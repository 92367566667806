import { GoogleSpreadsheet } from "google-spreadsheet";

//Constantes

const extraIni = {
  id: "",
  nombre: "",
  precio: 0
};
const dataInicial = {
  loading: true,
  count: 0,
  next: null,
  previous: null,
  results: []
};

//types

const LOADING = "LOADING";
const OBTENER_DELIVERY = "OBTENER_DELIVERY";
const DELIVERY_ERROR = "DELIVERY_ERROR";

const API_KEY = "AIzaSyByRA6Lq3iE4g1QukMC-aH8lYqYhsbG3sw";

const creds = require("../creds/quickstart-1592014430776-7a89ad3bdfe0.json"); // Credenciales de acceso readOnly

//Reducers

export default function deliveryReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case OBTENER_DELIVERY:
      return { action, loading: false, results: action.payload };
    case DELIVERY_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

//Acciones

export const obtenerDeliveryAccion = (sheet) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    /*  ---- AJUSTES POR CAMBIO DE CONEXION ---- */
    /* const doc = new GoogleSpreadsheet(key);
    await doc.useServiceAccountAuth(creds);
    doc.useApiKey(API_KEY);
    await doc.loadInfo();
    const sheet = doc.sheetsByIndex[0]; */
    //await sheet.loadCells("K11:M16");
    
    var extras = [];

    //Llenado de productos en objeto
    for (let index = 19; index <= 24; index++){
      if (sheet.getCell(index, 2).value!==null) {
        extras  = [ ...extras,
          {
            key: sheet.getCell(index, 0).value,
            nombre: sheet.getCell(index, 1).value,
            precio: sheet.getCell(index, 2).value
          }
        ]  
      }
    }

    dispatch({
      type: OBTENER_DELIVERY,
      payload: extras,
    });
  } catch (error) {
    console.log("Se creo este error");
    console.log("ErrorApi: " + error);
    dispatch({
      type: DELIVERY_ERROR,
    });
  }
};

