import { GoogleSpreadsheet } from "google-spreadsheet";
import groupArray from "group-array";

//Constantes

const pagoIni = {
  tipo: "",
  nombreBanco: "",
  cuenta: "",
  titular: ""
};
const dataInicial = {
  loading: true,
  count: 0,
  next: null,
  previous: null,
  results: []
};

//types

const LOADING = "LOADING";
const OBTENER_FORMA_PAGO = "OBTENER_FORMA_PAGO";
const FORMA_PAGO_ERROR = "FORMA_PAGO_ERROR";

const API_KEY = "AIzaSyByRA6Lq3iE4g1QukMC-aH8lYqYhsbG3sw";

const creds = require("../creds/quickstart-1592014430776-7a89ad3bdfe0.json"); // Credenciales de acceso readOnly

//Reducers

export default function formaPagosReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case OBTENER_FORMA_PAGO:
      return { action, loading: false, results: action.payload };
    case FORMA_PAGO_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

//Acciones

export const obtenerFormaPagosAccion = (sheet) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    /*  ---- AJUSTES POR CAMBIO DE CONEXION ---- */
    /* const doc = new GoogleSpreadsheet(key);
    await doc.useServiceAccountAuth(creds);
    doc.useApiKey(API_KEY);
    await doc.loadInfo();
    const sheet = doc.sheetsByIndex[0]; */
    /* await sheet.loadCells("K3:Q7"); */
    
    var forma_pago = [];

    //Llenado de productos en objeto
    for (let index = 9; index <= 15; index++){
      if (sheet.getCell(index, 6).value==='si') {
        forma_pago  = [ ...forma_pago,
          {
            cod: sheet.getCell(index, 0).value,
            tipo: sheet.getCell(index, 1).value,
            nombreBanco: sheet.getCell(index, 2).value,
            cuenta: sheet.getCell(index, 3).value,
            titular: sheet.getCell(index, 4).value,
            cedula: sheet.getCell(index, 5).value,
            activo: sheet.getCell(index, 6).value,
          }
        ]  
      }
    }

    dispatch({
      type: OBTENER_FORMA_PAGO,
      payload: forma_pago,
    });
  } catch (error) {
    console.log("Se creo este error");
    console.log("ErrorApi: " + error);
    dispatch({
      type: FORMA_PAGO_ERROR,
    });
  }
};

