import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import brandCardHeaderStyles from "./BrandCardHeader.style";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const preventDefault = (event) => event.preventDefault();


const Redes = (clienteDB) => {
  let cliente = clienteDB.cliente;
  return (
    <Grid container item xs={5} md={12} style={{ marginLeft: "5px" }}>
      {/* Instagram */}
      {cliente.instagram != null && (
        <Grid item xs={4} container justifyContent="flex-center">
          <IconButton
            size="small"
            href={cliente.instagram}
            target="_blank"
            onClick={(e) => preventDefault}
          >
            <InstagramIcon fontSize="small" htmlColor="white"></InstagramIcon>
          </IconButton>
        </Grid>
      )}
      {/* Telefono */}
      <Grid item xs={4} container justifyContent="flex-center">
        <IconButton
          size="small"
          href={"https://api.whatsapp.com/send?phone=" + cliente.telefono}
          target="_blank"
          onClick={(e) => preventDefault}
        >
          <WhatsAppIcon fontSize="small" htmlColor="white"></WhatsAppIcon>
        </IconButton>
      </Grid>
      {/* Email */}
      {cliente.correo_atencion != null && (
        <Grid item xs={4} container justifyContent="flex-center">
          <IconButton
            size="small"
            href={"mailto:" + cliente.correo_atencion}
            target="_blank"
            onClick={(e) => preventDefault}
          >
            <MailOutlineIcon
              fontSize="small"
              htmlColor="white"
            ></MailOutlineIcon>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

const BrandCardHeader = withStyles(brandCardHeaderStyles, {
  name: "BrandCardHeader"
  
})(({ classes, cliente }) => (
  <Grid container className={classes.root}>
    {/* avatar */}
    <Grid
      container
      xs={3}
      md={1}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={1} container justify="center">
        <Avatar
          alt={"brand logo"}
          className={classes.avatar}
          src={cliente.photoURL}
        />
      </Grid>
    </Grid>
    <Grid container xs={9} md={11}>
      {/*  Titulo tienda */}
      <Grid item xs={12}>
        <Typography className={classes.extra}>{cliente.empresa}</Typography>
      </Grid>
      {/* Direccion */}
      <Grid item xs={12}>
        {cliente.direccion != null ? (
          <>
            <LocationOnIcon
              fontSize="small"
              htmlColor="white"
              className={classes.iconos}
            ></LocationOnIcon>
            <Typography variant="caption" color="initial">
              {" "}
              {cliente.direccion}
            </Typography>
          </>
        ) : (
          ""
        )}
      </Grid>
      <Grid container xs={12}  >
        {/* Horario 2 */}
        <Grid item xs={12} md={10}>
          {cliente.horario != null ? (
            <>
              <AccessTimeIcon
                fontSize="small"
                htmlColor="white"
                className={classes.iconos}
              ></AccessTimeIcon>{" "}
              <Typography variant="caption" color="initial">
                {" "}
                {cliente.horario}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={10}>
          {cliente.horario2 != null ? (
            <>
              <AccessTimeIcon
                fontSize="small"
                htmlColor="white"
                className={classes.iconos}
              ></AccessTimeIcon>{" "}
              <Typography variant="caption" color="initial">
                {" "}
                {cliente.horario2}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          {/* Redes */}
          <Redes cliente={cliente} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
));

BrandCardHeader.propTypes = {
  image: PropTypes.string,
  extra: PropTypes.string,
};
BrandCardHeader.defaultProps = {
  image: "",
  extra: "",
};

export default BrandCardHeader;
