import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { useBlogTextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/blog";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import { QrCustom } from "./QrCustom";
import { Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(2), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    maxWidth: 600,
    marginLeft: "auto",
    overflow: "initial",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: spacing(2),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },
  media: {
    width: "88%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: "48%",
    borderRadius: spacing(2),
    backgroundColor: "#fff",
    position: "relative",
    [breakpoints.up("md")]: {
      width: "100%",
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: "translateX(-8px)",
    },
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 0,
      left: 0,
      width: "75%",
      height: "95%",
      backgroundImage: "linear-gradient(147deg, #efefef 0%, #32bad4 74%)",
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: "initial",
  },
}));

export const ClienteDatos = React.memo(function BlogCard(props) {
  const styles = useStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  const shadowStyles = useOverShadowStyles();
  const preventDefault = (event) => event.preventDefault();

  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <QrCustom url={props.urlLarge} />

      <CardContent>
        <TextInfoContent
          classes={contentStyles}
          overline={"Enhorabuena!"}
          heading={"Nueva Tienda Online"}
          body={
            <Typography>
              <Link
                href={props.urlLarge}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => preventDefault}
              >
                URL de tu tienda:
                <br /> {props.urlLarge}
              </Link>
            </Typography>
          }
        />
      </CardContent>
    </Card>
  );
});

export default ClienteDatos;
