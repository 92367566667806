import { GoogleSpreadsheet } from "google-spreadsheet";

//Constantes

const extraIni = {
  id: "",
  nombre: "",
  precio: 0
};
const dataInicial = {
  loading: true,
  count: 0,
  next: null,
  previous: null,
  results: []
};

//types

const LOADING = "LOADING";
const OBTENER_EXTRAS = "OBTENER_EXTRAS";
const EXTRAS_ERROR = "EXTRAS_ERROR";

const API_KEY = "AIzaSyByRA6Lq3iE4g1QukMC-aH8lYqYhsbG3sw";

const creds = require("../creds/quickstart-1592014430776-7a89ad3bdfe0.json"); // Credenciales de acceso readOnly

//Reducers

export default function extrasReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case OBTENER_EXTRAS:
      return { action, loading: false, results: action.payload };
    case EXTRAS_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

//Acciones

export const obtenerExtrasAccion = (settings) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    /*  ---- AJUSTES POR CAMBIO DE CONEXION ---- */
    /* const doc = new GoogleSpreadsheet(key);
    await doc.useServiceAccountAuth(creds);
    doc.useApiKey(API_KEY);
    await doc.loadInfo();
    const sheet = doc.sheetsByIndex[0]; */
    /* await sheet.loadCells("K20:N37"); */
    
    var extras = [];
    //Llenado de productos en objeto
    for (let index = 28; index <= 104; index++){
      if (settings.getCell(index, 3).value!==null) {
        extras  = [ ...extras,
          {
            key: settings.getCell(index, 0).value,
            grupo: settings.getCell(index, 1).value.toLowerCase() ,
            nombre: settings.getCell(index, 2).value,
            precio: settings.getCell(index, 3).value
          }
        ]  
      }
    }

    dispatch({
      type: OBTENER_EXTRAS,
      payload: extras,
    });
  } catch (error) {
    console.log("ErrorApi: " + error);
    dispatch({
      type: EXTRAS_ERROR,
    });
  }
};

