import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Card";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useCompradorContext } from "../context/CompradorContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    width: "100%",
    margin: "5px",
    "& .MuiTextField-root": {
      margin: "4px",
      width: "100%",
    },
    boxShadow: "none",
  },
  formRoot: {
    margin: "10px",
    width: "94%",
    boxShadow: "none",
  },
  formControl: {
    minWidth: 200,
    maxWidth: 340,
    marginBottom: "8px",
    boxShadow: "none",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bullet: {
    display: "inline-block",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  boton: {
    backgroundColor: "#128C7E",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  grid: {
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    overflow: "hidden",
  },
}));

export default function Cliente(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });

  const compradorIni = {
    pickup: true,
    nombre: null,
    telefono: null,
    direccion: null,
    comentarios: null,
    docidentidad: null,
    instagram: "",
    formaPago: {
      cod: "",
      tipo: "",
      nombreBanco: "",
      cuenta: "",
      titular: "",
      cedula: "",
    },
  };

  //const [comprador, setComprador] = React.useState(compradorIni);

  const { comprador, setComprador } = useCompradorContext();

  const handleChangeRetiro = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    console.log("Estado", state.checkedB);
    if (!state.checkedB) {
      props.setDelivery(0);
    }
    setComprador({ ...comprador, pickup: state.checkedB });
  };
  //Select Delivery
  const handleChangeDelivery = (event) => {
    props.setDelivery(event.target.value);
  };

  const nro_documento = props.datosCLiente.nro_documento;
  const numero = props.datosCLiente.numero;
  const cuenta_ig = props.datosCLiente.cuenta_ig;
  const comentarios = props.datosCLiente.comentarios;
  const sector = props.datosCLiente.sector;
  const retiro = props.datosCLiente.retiro;

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} style={{ marginLeft: "16px", boxShadow: "none" }}>
        <Typography variant="h6" component="h2">
          Datos del Cliente
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form className={classes.formRoot} noValidate autoComplete="off">
          <div>
            {retiro === "si" ? (
              <FormGroup component="fieldset">
                <FormControlLabel
                  component="legend"
                  control={
                    <Switch
                      checked={state.checkedB}
                      onChange={handleChangeRetiro}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="Retirar en tienda"
                ></FormControlLabel>
              </FormGroup>
            ) : (
              ""
            )}
            {nro_documento === "si" ? (
              <TextField
                required
                id="docidentidad"
                label="Nro Documento de Identidad"
                placeholder="15.347.980"
                variant="outlined"
                size="small"
                value={comprador.docidentidad}
                onChange={(e) =>
                  setComprador({
                    ...comprador,
                    docidentidad: e.target.value,
                  })
                }
              />
            ) : (
              ""
            )}
            <TextField
              required
              id="name"
              label="Nombre y Apellido"
              placeholder="Jhon Snow"
              variant="outlined"
              size="small"
              value={comprador.nombre}
              onChange={(e) =>
                setComprador({
                  ...comprador,
                  nombre: e.target.value,
                })
              }
            />
            {numero === "si" ? (
              <TextField
                required
                id="phone"
                label="Nro. de Contacto"
                placeholder="041x-xxx.xx.xx"
                variant="outlined"
                size="small"
                fullWidth
                value={comprador.telefono}
                onChange={(e) =>
                  setComprador({
                    ...comprador,
                    telefono: e.target.value,
                  })
                }
              />
            ) : (
              ""
            )}
            {!state.checkedB && sector === "si" ? (
              <div>
                <Box style={{ marginTop: "10px", marginLeft: "8px" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="Selectdeliveries">
                      Zona de Entrega
                    </InputLabel>
                    <Select
                      labelId="Selectdeliveries"
                      id="Selectdeliveries"
                      value={props.delivery}
                      onChange={handleChangeDelivery}
                    >
                      {props.deliveries.map((item) => (
                        <MenuItem
                          value={item.precio}
                        >{`${item.nombre} - ${props.moneda}: ${item.precio}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TextField
                  required
                  id="outlined-disabled"
                  label="Urb o Sector / Calle & Edf"
                  placeholder="Urb. La Casona, calle 1, edif A..."
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={comprador.direccion}
                  onChange={(e) =>
                    setComprador({
                      ...comprador,
                      direccion: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              ""
            )}
            {cuenta_ig === "si" ? (
              <TextField
                id="instagram"
                label="Cuenta Instagram [opcional]"
                placeholder="Para ofrecerte promociones especiales."
                variant="outlined"
                size="small"
                fullWidth
                value={comprador.cuenta_ig}
                onChange={(e) =>
                  setComprador({
                    ...comprador,
                    instagram: e.target.value,
                  })
                }
              />
            ) : (
              ""
            )}

            {comentarios === "si" ? (
              <TextField
                id="comments"
                label="Comentarios Adicionales"
                placeholder="Cualquier comentario"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                size="small"
                onChange={(e) =>
                  setComprador({
                    ...comprador,
                    comentarios: e.target.value,
                  })
                }
              />
            ) : (
              ""
            )}
          </div>
        </form>
      </Grid>
    </Grid>
  );
}
