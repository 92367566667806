import React from "react";

import { ThemeProvider } from "@material-ui/core";
import theme from "./temaConfig";
import CompradorProvider from "./context/CompradorContext";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Contenedor from "./components/Contenedor";
import { NuevoCliente } from "./components/NuevoCliente";
import CrearLicencia from "./components/CrearLicencia";
import Vendedores from "./components/Vendedores";
import licenses from "./components/Licenses";
import Clientes from "./components/ClientesAll";
import ClientesConLicencia from "./components/ClientesConLicencia";
import ClienteRegistro from "./components/ClienteRegistro";
import Prospectos from "./components/Prospectos";
import { Box } from "@material-ui/core";
function App() {
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Router>
          <Route path="/a/addClient/:vendor" component={ClienteRegistro} />
          <Route path="/admin/nuevoCliente/:vendor" component={NuevoCliente} />
          <Route path="/admin/createLicense" component={CrearLicencia} />
          <Route path="/admin/vendor" component={Vendedores} />
          <Route path="/admin/license" component={licenses} />
          <Route path="/admin/clientes" component={Clientes} />
          <Route
            path="/admin/clientes_licencia"
            component={ClientesConLicencia}
          />
          <Route path="/admin/prospectos" component={Prospectos} />
          <CompradorProvider>
            <Route path="/:nameURL" component={Contenedor} exact />
            <Route path="/" component={Contenedor} exact />
          </CompradorProvider>
        </Router>
      </ThemeProvider>
    </Box>
  );
}

export default App;
