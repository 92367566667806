import { createTheme } from "@material-ui/core/styles";
import { red, green, teal } from "@material-ui/core/colors";

const theme = createTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#f8f8f8",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "10px",
        minHeight: "10px",
      },
    },
    MuiListSubheader: {
      root: {
        fontWeight: "600",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: "300",
      },
    } 
  },
  palette: {
    secondary: {
      main: red["A700"],
    },
    primary: {
      main: green[500],
      contrastText: "#fff",
    },
    default: {
      main: teal[900],
      contrastText: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#f8f8f8",
    },
  },
  typography: {
    fontFamily: "Nunito, Arial",
    button: {
      fontSize: 12,
    },
  },
  
});

export default theme;
