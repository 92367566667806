import axios from "axios";

export default function sendMail(name, email, message, tipoMail) {
  let url = "https://api.whatspidelo.com/api/mail/send";
  let data = {
    name: name,
    email: email,
    message: message,
    tipomail: tipoMail,
  };
  axios
    .post(url, data)
    .then((response) => {
      if (response.data.msg === "success") {
        console.log("Message Sent.");
      } else if (response.data.msg === "fail") {
        console.log("Message failed to send.");
      }
    })
    .catch(() => {
      console.log("Message not sent");
    });
}
