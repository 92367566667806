import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  CircularProgress,
  Paper,
  Box,
  Grid,
  ListSubheader,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ProductoCard from "./ProductoCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    overflow: "auto",
    maxWidth: "100%",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    width: "100%",
  },
  paperProgress: {
    padding: theme.spacing(2),
    width: "100%",
    textAlign: "center",
  },
  paper: {
    padding: "theme.spacing(0)",
    width: "100%",
    textAlign: "center",
  },
  listItem: {
    padding: "4px",
  },
}));

export default function ProductosList(props) {
  const classes = useStyles();
  const [tipo, setTipo] = React.useState('');
  const [factor, setFactor] = React.useState(1);
 
  const actualizarTipo = () => async (tipo, producto) => {
    
    var factor1=1;
    switch (tipo) {
      case 0:
        setFactor (0.25);
        factor1=(0.25);
        break;
      case 1:
        setFactor (0.5);
        factor1=(0.5);
        break;
      case 2:
        setFactor( 1);
        factor1=(1);
          break;
      default:
        setFactor (1);
        factor1=(1);
        break;
    }
    const arrayAdd = await props.carrito.map((item) =>
        item.key === producto.key
          ? {
              key: item.key,
              title: item.title,
              thumbnail: item.thumbnail,
              price: item.precioReal * factor1,
              cantidad: item.cantidad,
              extras: item.extras,
              grupo: item.grupo,
              tipo: item.tipo,
              unidadTipo: tipo,
              factor: factor1,
              precioReal: item.precioReal,
              listaPersonalizada: item.listaPersonalizada,
            }
          : item
      );
     
      console.log(arrayAdd);
      props.actualizarCarrito(arrayAdd);
      props.actualizarPrecioProductos (producto.key,factor1);
  };

  return (
    <div>
      {props.loading || props.productos === undefined ? (
        <Grid container justify="center">
          <Paper className={classes.paperProgress}>
            <CircularProgress />
          </Paper>
        </Grid>
      ) : (
        <Box>
          <List className={classes.root} subheader={<li />}>
            {Object.keys(props.productos).map(function (keyName, keyIndex) {
              return (
                <li key={"section-" + keyName}>
                  <ul className={classes.ul}>
                    <ListSubheader
                      className={classes.listSubHeader}
                      id={"section-" + encodeURIComponent(keyName)}
                    >
                      {keyName}
                    </ListSubheader>
                    {props.productos[keyName].map(
                      (item) =>
                        item.filter && (
                          <ListItem key={item.key} className={classes.listItem}>
                            <ProductoCard
                              carro={props.carrito.find(
                                (i) => i.key === item.key
                              )}
                              
                              key={item}
                              title={item.title}
                              price={item.price}
                              thumbnail={item.thumbnail}
                              medida={item.medida}
                              activo={item.activo}
                              unidadTipo={factor}
                              factor={item.factor}
                              listaPersonalizada={item.listaPersonalizada}
                              agregar={(e) => props.agregar(item)}
                              aumentar={(e) => props.aumentar(item)}
                             /*  setCarrito={() => props.setCarrito(item)}
                              actualizarUnidadTipo={(e) =>
                                props.actualizarUnidadTipo(item)
                              }
                              varUnidadTipos={props.unidadTipos}
                              setUnidadTipos={
                                props.setUnidadTipos} */
                              setTipo={()=>setTipo()}
                              actualizarTipo={actualizarTipo()}
                              disminuir={(e) =>
                                props.disminuir(
                                  props.carrito.find((i) => i.key === item.key)
                                )
                              }
                              moneda={props.moneda}
                              grupo={item.grupo}
                              tipo={item.tipo}
                              setOpenDrawer={(e) => props.setOpenDrawer(item)}
                              extras={props.extras}
                            />
                          </ListItem>
                        )
                    )}
                  </ul>
                </li>
              );
            })}
          </List>
        </Box>
      )}
    </div>
  );
}
