import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useSelector, useDispatch } from "react-redux";
import { obtenerClientesAccion } from "../redux/ClienteDucks";
import {
  obtenerLicenciasAccion,
  nuevaLicenciaAccion,
  eliminarLicenciaAccion,
} from "../redux/LicenciaDucks";
import moment from "moment";
import "moment/locale/es";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 50,
  },
  formulario: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "90%",
  },
}));

const Licenses = () => {
  const dispatch = useDispatch();
  const licencias = useSelector((store) => store.licencias.results);
  const clienteDB = useSelector((store) => store.cliente.results);

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        dispatch(obtenerLicenciasAccion());
        dispatch(obtenerClientesAccion());
      } catch (error) {
        console.log(error);
      }
    };

    obtenerDatos();
  }, [dispatch]);

  const classes = useStyles();

  const licenciaIni = {
    fecha_ini: null,
    fecha_fin: null,
    sheetID: null,
  };

  const [licencia, setLicencia] = React.useState(licenciaIni);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid md={8}>
          <Typography variant="h5" color="initial">
            Vendedores
          </Typography>
          <Divider />
          {/* (loading && typeLoading==='eliminar') &&
                  <LinearProgress color="secondary" /> */}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>SheetID</StyledTableCell>
                  <StyledTableCell>License Key</StyledTableCell>
                  <StyledTableCell>Vencimiento</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licencias.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.sheetID}</TableCell>
                    <TableCell>{item.license_nro}</TableCell>

                    <TableCell>
                      {moment(item.fecha_fin.toDate()).format("L")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) =>
                          dispatch(eliminarLicenciaAccion(item.id))
                        }
                      >
                        Borrar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid md={4}>
          <Typography variant="h5" color="initial">
            Registrar vendedor
          </Typography>
          <Divider />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <form className={classes.formulario}>
              <Grid item>
                <DatePicker
                  format="DD-MM-YYYY"
                  label="Fecha Inicio"
                  variant="outlined"
                  value={licencia.fecha_ini}
                  onChange={(e) => setLicencia({ ...licencia, fecha_ini: e })}
                  className={classes.formControl}
                />
                {/* </Grid>
                      <Grid item   > */}
                <DatePicker
                  format="DD-MM-YYYY"
                  label="Fecha Vencimiento"
                  variant="outlined"
                  value={licencia.fecha_fin}
                  onChange={(e) => setLicencia({ ...licencia, fecha_fin: e })}
                  className={classes.formControl}
                />
                {/*  </Grid>
                      <Grid item > */}
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Google SheeID
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) =>
                      setLicencia({ ...licencia, sheetID: e.target.value })
                    }
                  >
                    {clienteDB.map((item) => (
                      <MenuItem value={item.id}>{item.id}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => dispatch(nuevaLicenciaAccion(licencia))}
              >
                <Typography variant="subtitle2">Crear</Typography>
              </Button>
            </form>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default Licenses;
