import { db } from "../firebase";
import shortid from "shortid";

//Constantes

const dataInicial = {
  loading: false,
  typeLoading: null,
  results: [],
};

//types
const LOADING = "LOADING";
const NUEVO_VENDEDOR_EXITO = "NUEVO_VENDEDOR_EXITO";
const ELIMINAR_VENDEDOR_EXITO = "ELIMINAR_VENDEDOR_EXITO";
const VENDEDOR_EXITO = "NUEVO_VENDEDOR_EXITO";
const VENDEDOR_ERROR = "VENDEDOR_ERROR";
const OBTENER_VENDEDOR_EXITO = "OBTENER_VENDEDOR_EXITO";

//Reducers

export default function vendedorReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true, typeLoading: action.payload };
    case VENDEDOR_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "leer",
        results: [...action.payload],
      };
    case OBTENER_VENDEDOR_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "leer",
        results: action.payload,
      };
    case NUEVO_VENDEDOR_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "nuevo",
        results: [...action.payload],
      };
    case ELIMINAR_VENDEDOR_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "eliminar",
        results: [...action.payload],
      };
    case VENDEDOR_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

//Acciones

export const obtenerVendedoresAccion = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: "leer",
  });
  try {
    //const vendedorDB = await db.collection('vendedores').doc(short).get()
    const data = await db.collection("vendedores").get();
    const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    dispatch({
      type: VENDEDOR_EXITO,
      payload: results,
    });
  } catch (error) {
    console.log(error);
  }
};

export const nuevoVendedorAccion = (nuevoVendedor) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: LOADING,
    payload: "nuevo",
  });
  try {
    //const vendedorDB = await db.collection('vendedores').doc(short).get()
    const id = shortid.generate();
    const cod_vendor =
      nuevoVendedor.nombre.toUpperCase().substr(0, 3) +
      id.toUpperCase().substr(0, 3);
    await db
      .collection("vendedores")
      .doc(id)
      .set({
        ...nuevoVendedor,
        cod_vendor: cod_vendor,
        created_at: Date.now(),
      });
    const data = await db.collection("vendedores").get();

    const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({
      type: VENDEDOR_EXITO,
      payload: results,
    });
  } catch (error) {}
};

export const eliminarVendedorAccion = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: "eliminar",
  });
  try {
    //const vendedorDB = await db.collection('vendedores').doc(short).get()

    await db.collection("vendedores").doc(id).delete();
    const data = await db.collection("vendedores").get();
    const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({
      type: ELIMINAR_VENDEDOR_EXITO,
      payload: results,
    });
  } catch (error) {}
};

export const obtenerVendedorAccion = (cod_vendor) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: LOADING,
    payload: "leer",
  });
  try {
    var vendor = null;
    var query = await db
      .collection("vendedores")
      .where("cod_vendor", "==", cod_vendor)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          dispatch({
            type: VENDEDOR_ERROR,
          });
          return;
        } else {
          vendor = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
        }
      })
      .catch((err) => {
        console.log("Error getting documents VENDOR", err);
        dispatch({
          type: VENDEDOR_ERROR,
        });
      });

    if (vendor != null) {
      dispatch({
        type: OBTENER_VENDEDOR_EXITO,
        payload: vendor,
      });
    }
  } catch (error) {}
};
