import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  LinearProgress,
  Link
} from "@material-ui/core";
import { obtenerClientesAccion, demovencido, demoactivo } from "../redux/ClientesAllDucks";
import { useSelector, useDispatch } from "react-redux";
import sendMail from "../redux/Mail";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 50,
  },
  formulario: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
}));

const Clientes = () => {
  const dispatch = useDispatch();
  const clientes = useSelector((store) => store.clientes.results);
  const loading = useSelector((store) => store.clientes.loading);
  const typeLoading = useSelector((store) => store.clientes.typeLoading);

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        dispatch(obtenerClientesAccion());
      } catch (error) {
        console.log(error);
      }
    };

    obtenerDatos();
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid md={12}>
          <Typography variant="h5" color="initial">
            Clientes con periodo de prueba activo: <b>{demoactivo} </b> / Clientes prueba vencida: <b> {demovencido}</b>
          </Typography>
          <Divider />
          {loading && typeLoading === "eliminar" && (
            <LinearProgress color="secondary" />
          )}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell>Cliente</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Telefono</StyledTableCell>
                  <StyledTableCell>Desde / Hasta</StyledTableCell>
                  <StyledTableCell>Diferencia</StyledTableCell>
                  <StyledTableCell>Hoja</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientes.map((item, index) => (
                  <TableRow
                    key={item.id}
                    style={{ backgroundColor: item.colorback }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.nombre} / <Link href={"https://shop.whatspidelo.com/" + item.enlace} target={"_blank"}> {item.enlace}</Link></TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.telefono}</TableCell>
                    <TableCell>{item.desde} - {item.hasta}</TableCell>
                    <TableCell>{item.diferencia}</TableCell>
                    <TableCell>
                    <Link href={"https://docs.google.com/spreadsheets/u/2/d/" + item.hoja} target={"_blank"}> Ver</Link></TableCell>
                    <TableCell>
                      {item.showboton && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            sendMail(
                              item.empresa,
                              item.email,
                              item.diferencia,
                              1
                            )
                          }
                        >
                          Enviar
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Clientes;
