import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import React from "react";
import NumberFormat from "react-number-format";

const imageNotFound = "imageNotFound.jpg";
const useStyles = makeStyles((theme) => ({
  principal: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(0),
    paddingBottom: 0,
    marginLeft: "8px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    paddingLeft: theme.spacing(1),
    paddingBottom: 0,
  },
  content: {
    width: "100%",
    padding: 4,
    paddingBottom: 0,
  },
  cover: {
    width: 100,
  },
  controls: {
    display: "flex",
    alignItems: "center",

    paddingBottom: 0,
  },
  playIcon: {
    height: 38,
    width: 38,
  },

  mediaMax: {
    height: "300px",
    width: "300px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    marginTop: "100px",
  },
  controlsCart: {
    display: "flex",
    alignItems: "space-between",
    paddingTop: "4px",
  },

  media: {
    minHeight: "150px",
    minWidth: "150px",
    objectFit: "cover",
  },
}));

export default function ProductoCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const addToCart = (e) => {
    //setAgregado(true)
    //props.aumentar(e)
    props.agregar();
    return;
  };

  const handleOpen = () => {
    if (props.thumbnail !== imageNotFound) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [tipo, setTipo] = React.useState(2);

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
    props.setTipo(event.target.value);

    props.actualizarTipo(event.target.value, props.carro);
  };
  return (
    <Card variant="outlined" className={classes.principal}>
      <CardMedia
        className={classes.media} //cover
        image={props.thumbnail}
        onClick={(e) => handleOpen()}
        sx={{ padding: "1em 1em 0 1em", objectFit: "cover" }}
      />
      <div className={classes.details}>
        <CardContent className={classes.content} style={{ paddingBottom: 0 }}>
          {/* Titulo y descripción del producto */}
          <Grid xs={12} style={{ minHeight: "50px" }}>
            <Typography style={{ lineHeight: "1.2" }}>{props.title}</Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 300, lineHeight: "1.2" }}
              color="textSecondary"
            >
              {props.medida}
            </Typography>
          </Grid>
          {/* Precio */}
          <Grid container spacing={0}>
            <Grid
              item
              xs={6}
              justifyContent="flex-start"
              style={{ alignSelf: "center" }}
            >
              <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                <NumberFormat
                  value={Number(props.price * props.factor)}
                  displayType={"text"}
                  decimalScale={2}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={" " + props.moneda + ": "}
                />
              </Typography>
            </Grid>
            {/* Extras */}
            <Grid item xs={6} justifyContent="flex-end">
              {!!props.carro && props.grupo !== null && props.grupo !== "" && (
                <Button
                  size="small"
                  label="Adicionales"
                  variant="contained"
                  color="#5c6bc0"
                  onClick={(e) => props.setOpenDrawer()}
                  startIcon={<PlaylistAddIcon />}
                  style={{
                    fontSize: "11px",
                    background: "#4caf50",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "6px",
                  }}
                  fullWidth
                >
                  Extras
                </Button>
              )}
            </Grid>
          </Grid>

          {/*  <Typography align="left" > */}

          {props.carro === undefined ? (
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex"
              wrap="nowrap"
            >
              <Grid item style={{marginTop:'10px', marginBottom:'5px'}}>
                <div className={classes.controlsCart}>
                  {props.activo !== "agotado" ? (
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={(e) =>
                        addToCart(props.carro !== undefined && props.carro)
                      }
                    >
                      Agregar al carrito
                    </Button>
                  ) : (
                    <Chip
                      variant="outlined"
                      label="¡Agotado!"
                      style={{
                        color: "#F6AE2D",
                        borderColor: "#F6AE2D",
                      }}
                      icon={
                        <NotInterestedIcon
                          style={{
                            color: "#F6AE2D",
                            borderColor: "#F6AE2D",
                          }}
                        />
                      }
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              alignItems="stretch"
              spacing={0.5}
              direction="column"
              justifyContent="space-between"
            >
              <div className={classes.controlsCart}>
                <Grid
                  item
                  xs={6}
                  alignItems="center"
                  style={{ alignSelf: "center", minHeight: "30px" }}
                >
                  <div className={classes.controlsCart}>
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label="Disminuir"
                      onClick={(e) =>
                        props.disminuir(
                          props.carro !== undefined && props.carro
                        )
                      }
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                    <Chip
                      size="small"
                      label={
                        props.carro !== undefined ? props.carro.cantidad : 0
                      }
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    />
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label="Agregar más"
                      onClick={(e) =>
                        addToCart(props.carro !== undefined && props.carro)
                      }
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={6} style={{ padding: 5 }}>
                  {props.tipo !== null && props.tipo !== "" && (
                    <FormControl
                      style={{ mt: "10px" }}
                      sx={{ m: 1, minWidth: 80 }}
                      size="small"
                      fullWidth
                    >
                      <InputLabel id="demo-select-small-label">
                        {props.tipo}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small-label"
                        value={props.carro.unidadTipo}
                        label={props.tipo}
                        onChange={handleChangeTipo}
                        style={{ paddingRight: "15px" }}
                      >
                        {props.listaPersonalizada !== null &&
                        props.tipo !== "Peso"
                          ? props.listaPersonalizada.map((valor) => (
                              <MenuItem value={valor}>{valor}</MenuItem>
                            ))
                          : props.listaPersonalizada !== null && props.listaPersonalizada.map((option) => (
                              <MenuItem value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </div>
            </Grid>
          )}

          {/* <IconButton color='primary' aria-label="" onClick={  props.agregar}>
              <AddShoppingCartIcon   />
           </IconButton> */}
          {/*  </Typography> */}
        </CardContent>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CardMedia
          className={classes.mediaMax}
          image={
            props.thumbnail ||
            "https://api.whatspidelo.com/uploads/1611620549-NoEncontrada.jpg"
          }
          onClick={(e) => handleClose(e)}
        />
      </Modal>
    </Card>
  );
}
