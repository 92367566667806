import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import {
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Chip,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "../styles/total.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  principal: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(0),
    paddingBottom: 0,
    boxShadow: "none",
  },
  marginTop15: {
    //marginTop: "15px !important",
  },
  boton: {
    backgroundColor: "#128C7E",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  inline: {
    display: "inline",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(1),
    paddingBottom: 0,
    width: "70%",
  },
  content: {
    flex: "0 0 auto",
    padding: "5px",
  },
  controls: {
    display: "flex",
    alignItems: "space-between",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ul: {
    backgroundColor: "inherit",
    padding: 1,
  },
  controlsCart: {
    display: "flex",
    alignItems: "space-between",
    paddingLeft: theme.spacing(1),
    paddingBottom: "8px",
  },
  media: {
    height: 95,
    width: 95,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    paddingTop: theme.spacing(1),
    borderRadius: "10px",
  },
}));

export default function Carrito(props) {
  const classes = useStyles();

  const extrasTotal = (arr) => {
    var ext = 0;
    if (arr.length > 0) {
      ext = arr.reduce(function (total, b) {
        return b.isChecked ? total + b.precio : total + 0;
      }, 0);
    }
    return ext;
  };

  const [tipo, setTipo] = React.useState("");

  const handleChangeTipo = async (event, prod) => {
    var factor = 1;
    switch (event.target.value) {
      case 0:
        factor = 0.25;
        break;
      case 1:
        factor = 0.5;
        break;
      case 2:
        factor = 1;
        break;
      default:
        factor = 1;
    }
    const arrayAdd = await props.carrito.map((item) =>
      item.key === prod.key
        ? {
            key: item.key,
            title: item.title,
            thumbnail: item.thumbnail,
            price: item.precioReal * factor,
            cantidad: item.cantidad,
            extras: item.extras,
            grupo: item.grupo,
            tipo: item.tipo,
            unidadTipo: event.target.value,
            factor: factor,
            precioReal: item.precioReal,
            listaPersonalizada: item.listaPersonalizada,
          }
        : item
    );
    console.log(arrayAdd);
    props.actualizarCarrito(arrayAdd);
    props.actualizarPrecioProductos(prod.key, factor);
  };

  return (
    <div className={classes.root}>
      <Grid id="carrito" container>
        <Typography
          variant="h6"
          component="h2"
          style={{ marginLeft: "15px", marginTop: "10px" }}
        >
          Mi pedido
        </Typography>
        <Grid item /*  className={classes.root} */>
          {props.carrito.length === 0 ? (
            <ListItem key={0} container>
              <Typography style={{ textAlign: "center", width: "inherit" }}>
                El carrito de compras está vacío.
              </Typography>
            </ListItem>
          ) : (
            <List className={classes.root}>
              {props.carrito.map((item) => (
                <ListItem
                  key={item.id}
                  alignItems="flex-start"
                  style={{ paddingBottom: 3 }}
                >
                  <Card className={classes.principal}>
                    <CardMedia
                      className={classes.media} //cover
                      image={item.thumbnail}
                      /* onClick={ e => handleOpen(props.thumbnail)} */
                    />
                    <div className={classes.details}>
                      <CardContent
                        className={classes.content}
                        style={{ paddingBottom: 0 }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Box style={{ height: "65px" }}>
                              <Typography
                                style={{
                                  lineHeight: "1",
                                  minHeight: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.title}
                                {item.extras.length > 0 &&
                                  ", con " +
                                    item.extras.reduce(function (total, b) {
                                      return b.isChecked
                                        ? total + 1
                                        : total + 0;
                                    }, 0) +
                                    " extras"}
                              </Typography>
                              {item.grupo !== null && item.grupo !== "" && (
                                <Button
                                  size="small"
                                  label="Adicionales"
                                  variant="contained"
                                  color="#5c6bc0"
                                  onClick={(e) => props.setOpenDrawer(item)}
                                  startIcon={<PlaylistAddIcon />}
                                  style={{
                                    fontSize: "11px",
                                    background: "#4caf50",
                                    color: "#fff",
                                    fontWeight: "600",
                                    padding: "6px",
                                  }}
                                >
                                  Ver extras
                                </Button>
                              )}
                              {item.tipo !== null && item.tipo !== "" && (
                                <FormControl
                                  style={{ marginLeft: "15px" }}
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  <InputLabel id="demo-select-small">
                                    {item.tipo}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={item.unidadTipo}
                                    label={item.tipo}
                                    onChange={(event) =>
                                      handleChangeTipo(event, item)
                                    }
                                    style={{ paddingRight: "15px" }}
                                  >
                                    {item.listaPersonalizada !== null &&
                                    item.tipo !== "Peso"
                                      ? item.listaPersonalizada.map((valor) => (
                                          <MenuItem value={valor}>
                                            {valor}
                                          </MenuItem>
                                        ))
                                      : item.listaPersonalizada !== null && item.listaPersonalizada.map(
                                          (option) => (
                                            <MenuItem value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          )
                                        )}
                                  </Select>
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={6}>
                              {/*  Precio */}
                              <Typography
                                variant="subtitle1"
                                color="colorPrimary"
                              >
                                <NumberFormat
                                  value={
                                    (Number(item.price) +
                                      extrasTotal(item.extras)) *
                                    Number(item.cantidad)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={2}
                                  prefix={" " + props.moneda + ": "}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {/*  botones */}
                              <div className={classes.controlsCart}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  aria-label="Disminuir"
                                  onClick={(e) => props.disminuir(item)}
                                >
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                                <Chip
                                  size="small"
                                  label={item.cantidad}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "4px",
                                  }}
                                />
                                <IconButton
                                  size="small"
                                  color="primary"
                                  aria-label="Agregar más"
                                  onClick={(e) => props.aumentar(item)}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  aria-label="Eliminar"
                                  onClick={(e) => props.eliminar(item.key)}
                                >
                                  <RemoveShoppingCartIcon />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </div>
                  </Card>
                </ListItem>
              ))}
            </List>
          )}

          <Grid
            container
            style={{
              margin: "auto",
              backgroundColor: "#f8f8f8",
              width: "90%",
              color: "#919191",
              padding: "0 15px 0 15px",
              marginTop: "30px",
            }}
          >
            {props.carrito.length > 0 &&
              props.delivery &&
              props.costo_delivery !== null && (
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{ marginTop: "16px", backgroundColor: "#f8f8f8" }}
                >
                  <Grid container>
                    <Grid item xs={10} style={{ paddingLeft: "10px" }}>
                      <Box justifyContent="flex-start" display="flex">
                        <Typography
                          variant="subtitle2"
                          color="initial"
                          className="marginTop15"
                        >
                          Subtotal:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2} style={{ paddingRight: "10px" }}>
                      <Box justifyContent="flex-end" display="flex">
                        <Typography
                          variant="subtitle2"
                          color="initial"
                          className="marginTop15"
                        >
                          {props.moneda === "USD" ? (
                            <NumberFormat
                              value={props.total}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"$"}
                            />
                          ) : (
                            <NumberFormat
                              value={props.total}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                            />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={10} style={{ paddingLeft: "10px" }}>
                      <Box justifyContent="flex-start" display="flex">
                        <Typography
                          variant="subtitle2"
                          color="initial"
                          className="marginTop15"
                        >
                          Delivery:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2} style={{ paddingRight: "10px" }}>
                      <Box justifyContent="flex-end" display="flex">
                        <Typography
                          variant="subtitle2"
                          color="initial"
                          className="marginTop15"
                        >
                          {props.moneda === "USD" ? (
                            <NumberFormat
                              value={props.costo_delivery}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"$"}
                            />
                          ) : (
                            <NumberFormat
                              value={props.costo_delivery}
                              decimalScale={2}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                            />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="rip"></div>
                  </Grid>
                </Grid>
              )}

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              <Grid item xs={7} style={{ paddingLeft: "10px" }}>
                <Box justifyContent="flex-start" display="flex">
                  <Typography
                    variant="h7"
                    color="initial"
                    className="marginTop15"
                  >
                    Total {" " + props.moneda + ":"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5} style={{ paddingRight: "10px" }}>
                <Box justifyContent="flex-end" display="flex">
                  {props.carrito.length > 0 &&
                  props.delivery &&
                  props.costo_delivery !== null ? (
                    <Typography
                      variant="h7"
                      color="initial"
                      className="marginTop15"
                    >
                      {props.moneda === "USD" ? (
                        <NumberFormat
                          value={props.total + props.costo_delivery}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      ) : (
                        <NumberFormat
                          value={props.total + props.costo_delivery}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                        />
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h7"
                      color="initial"
                      className="marginTop15"
                    >
                      {props.moneda === "USD" ? (
                        <NumberFormat
                          value={props.total}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"$"}
                        />
                      ) : (
                        <NumberFormat
                          value={props.total}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                        />
                      )}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {props.tasa !== null && (
            <Typography variant="subtitle2" color="initial">
              Tasa de Cambio Bs.:{" "} {props.tasa}
              {/* <NumberFormat
                value={props.tasa}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
              /> */}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
