import { db } from "../firebase";
import moment from "moment";
import "moment/locale/es";
import * as creds from "../creds/check_license.json";

//Constantes

const dataInicial = {
    loading: false,
    typeLoading: null,
    results: [],
};

//types
const LOADING = "LOADING";
const CLIENTES_EXITO = "NUEVO_CLIENTES_EXITO";

//Reducers

export default function clientesConLincenciaReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return {...state, loading: true, typeLoading: action.payload };
        case CLIENTES_EXITO:
            return {
                ...state,
                loading: false,
                typeLoading: "leer",
                results: [...action.payload],
            };
        default:
            return state;
    }
}

//Acciones

export const obtenerClientesConLicenciaAccion = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: "leer",
    });
    try {
        const licenciasGet = await db.collection("licencias").get();
        const licenciasResults = licenciasGet.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        var licencias = [];
        licenciasResults.map((item) => {
            licencias = [
                ...licencias,
                {
                    id: item.id,
                    fecha_ini: item.fecha_ini,
                    fecha_fin: item.fecha_fin,
                    filter: true,
                },
            ];
        });

        const vendedorGet = await db.collection("vendedores").get();
        const vendedorResults = vendedorGet.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        var vendedor = [];
        vendedorResults.map((item) => {
            vendedor = [
                ...vendedor,
                {
                    id: item.id,
                    nombre: item.nombre,
                },
            ];
        });

        const data = await db.collection("clientes").get();
        const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        var clientes = [];
        results.map(async(item) => {
            let creado = moment(item.created_at);
            let transcurrido = moment().diff(creado, "days"); // calculo la diferencia de dias desde hoy hasta el vencimiento
            var dif, hasta, fdesde;
            let demo = item.licencia === creds.licenceDemo ? "Si" : "No";
            let color;

            if (demo === "No" && item.licencia) {
                licencias.forEach((element) => {
                    if (element.id === item.sheetId) {
                        let fecha_fin = moment
                            .unix(element.fecha_fin.seconds)
                            .format("YYYY-MM-DD");
                        let hoy = moment().format("YYYY-MM-DD");
                        let fecha2 = moment(fecha_fin);
                        dif = fecha2.diff(hoy, "days");
                        hasta = fecha2.format("L");
                        fdesde = moment.unix(element.fecha_ini.seconds).format("L");
                        return false;
                    }
                });
                if (dif >= 1 && dif <= 5) {
                    color = "#f0ad4e";
                } else if (dif < 1) {
                    color = "#D3662E";
                } else {
                    color = "";
                }

                let boton = true;
                if (demo === "No" && dif >= 15) {
                    color = "green";
                    boton = false;
                }
                let nombre = ((item.apellido) && (item.nombre)) ? item.nombre + ' ' + item.apellido : 'No registrado';
                let vendedorNombre = '';

                vendedor.forEach((element) => {
                    if (element.id === item.vendedor_id) {
                        vendedorNombre = element.nombre;
                        return false;
                    }
                });
                console.log(item);
                if ((item.nameURL != 'demorestaurant') && (item.nameURL != 'demowhatspidelo')) {
                    clientes = [
                        ...clientes,
                        {
                            id: item.id,
                            empresa: item.nameURL,
                            email: item.email,
                            desde: fdesde,
                            hasta: hasta,
                            diferencia: dif,
                            nombre: nombre,
                            telefono: item.telefono,
                            colorback: color,
                            filter: true,
                            showboton: false,
                            vendedor: vendedorNombre,
                            hoja: item.sheetId
                        },
                    ];
                }
            }
        });

        clientes.sort(function(a, b) {
            if (a.diferencia > b.diferencia) {
                return 1;
            }
            if (a.diferencia < b.diferencia) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        dispatch({
            type: CLIENTES_EXITO,
            payload: clientes,
        });
    } catch (error) {
        console.log(error);
    }
};