import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BrandCardHeader from "./BrandCardHeader";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    fullwidth: true,
  },
  paper: {
    padding: theme.spacing(0),
    width: "100%",
    textAlign: "center",
  },
  avatar: {
    width: 48,
    height: 48,
    transform: "translateY(50%)",
    "& > img": {
      margin: 0,
    },
  },
}));

const ClienteOwn = (props) => {
  const classes = useStyles();

  return (
    <BrandCardHeader cliente={props.clienteDB} className={classes.avatar} />
  );
};

export default ClienteOwn;
