import {
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Link,
  Paper,
  Snackbar, Typography,
  makeStyles,
} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MuiAlert from "@material-ui/lab/Alert";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AppCategoriesBar from "../components/AppCategoriesBar";
import Carrito from "../components/Carrito";
import Cliente from "../components/Cliente";
import ClienteOwn from "../components/ClienteOwn";
import NavBar from "../components/NavBar";
import { useCompradorContext } from "../context/CompradorContext";
import { analytics } from "../firebase";
import "../index.css";
import { obtenerClienteAccion } from "../redux/ClienteDucks";
import { obtenerDeliveryAccion } from "../redux/DeliveryDucks";
import { obtenerExtrasAccion } from "../redux/ExtrasDucks";
import { obtenerFormaPagosAccion } from "../redux/FormaPagosDucks";
import { validarLicenciaAccion } from "../redux/LicenciaDucks";
import {
  actualizarPrecioProductosAccion,
  buscarProductosAccion,
  obtenerProductosAccion,
} from "../redux/ProductoDucks";
import DrawerExtras from "./DrawerExtras";
import FormasPago from "./FormasPago";
import ProductosCategorias from "./ProductosCategorias";
import ProductosList from "./ProductosList";

const estilos = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    paddingBottom: 90,
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },

  paperProgress: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "transparent",
    height: "35%",
  },
  boton: {
    backgroundColor: "#128C7E",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  grid: {
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    overflow: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#4cac33",
  },
  mediaPhone: {
    width: "200px",
    height: "200px",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundSize: "contain",
    borderRadius: "150px",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    backgroundColor: "white",
    padding: "40px",
    borderColor: "white",
  },
  mediaMarca: {
    width: "80px",
    height: "112px",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundSize: "contain",
  },
  mediaLogoInial: {
    width: "350px",
    height: "150px",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundSize: "contain",
  },

  secondColumn: {
    paddingTop: "50px",
  },
}));

const Contenedor = () => {
  const [heightCard, setHeightCard] = useState("auto");

  const { nameURL } = useParams();
  const classes = estilos();

  const dispatch = useDispatch();
  const clienteDB = useSelector((store) => store.cliente.cliente);
  const sheet = useSelector((store) => store.cliente.sheet);
  const settings = useSelector((store) => store.cliente.settings);
  const errorCliente = useSelector((store) => store.cliente.error);
  const errorMsjCliente = useSelector((store) => store.cliente.msjerror);
  const productos = useSelector((store) => store.productos.listaProductos);
  const loading = useSelector((store) => store.productos.loading);
  const loadingCliente = useSelector((store) => store.cliente.loading);
  const licenciaValida = useSelector((store) => store.licencias.status);
  const formaPagos = useSelector((store) => store.formaPagos.results);
  const extras = useSelector((store) => store.extras.results);
  const deliveries = useSelector((store) => store.delivery.results);

  const [carrito, setCarrito] = React.useState([]);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    tipo: "",
    mensaje: "",
  });
  const [error, setError] = React.useState({
    activo: false,
    mensaje: null,
  });
  const [extrasLoad, setExtrasLoad] = React.useState([]);
  const [unidadTipos, setUnidadTipos] = React.useState([]);
  const { vertical, horizontal, open, tipo, mensaje } = state;
  const [delivery, setDelivery] = React.useState(0);

  const [productPrice, setProductPrice] = React.useState([]);

  const compradorIni = {
    pickup: true,
    nombre: null,
    telefono: null,
    direccion: null,
    comentarios: null,
    docidentidad: null,
    instagram: "",
    formaPago: {
      cod: "",
      tipo: "",
      nombreBanco: "",
      cuenta: "",
      titular: "",
      cedula: "",
    },
  };

  const { comprador, setComprador } = useCompradorContext();
  const [openDrawer, setOpenDrawer] = React.useState(false); //Drawer de extras
  const [drawerProducto, setDrawerProducto] = React.useState(""); //Drawer de extras
  const preventDefault = (event) => event.preventDefault();

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        if (clienteDB.sheetId === null) {
          dispatch(obtenerClienteAccion(nameURL));
        }
        if (licenciaValida === null && clienteDB.sheetId != null) {
          dispatch(validarLicenciaAccion(clienteDB.sheetId));
        }
        if (sheet !== null) {
          dispatch(obtenerFormaPagosAccion(settings));
          dispatch(obtenerExtrasAccion(settings));
          dispatch(obtenerDeliveryAccion(settings));
          dispatch(obtenerProductosAccion(sheet));
        }
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, [dispatch, clienteDB, nameURL, licenciaValida, sheet, settings]);

  const handleClick = (newState) => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  //Actualizar comprador desde props

  const actualizarComprador = (e) => (value) => {
    /* console.log(value.nombre);
  setComprador({...comprador, nombre: value.nombre, telefono: value.telefono, direccion: value.direccion, comentarios: value.comentarios, docidentidad: value.docidentidad, instagram: value.instagram, pickup: value.pickup});
  //dispatch( actualizarComprador({...compradorNew, nombre: value.nombre, telefono: value.telefono, direccion: value.direccion, comentarios: value.comentarios, docidentidad: value.docidentidad, instagram: value.instagram, pickup: value.pickup}));
  */
    console.log(comprador);
    //  return urlws();
  };

  //Agregar Adicionales
  const addAdicionales = (key) => {
    console.log(key); //Sólo tomamos los adicionales seleccionados
    const arrayAdd = carrito.map((item) =>
      item.key === key
        ? {
            ...item,
            extras: [...extrasLoad],
            totalExtrasPrecio:
              Number(item.price) +
              extrasLoad.reduce(function (total, b) {
                return b.isChecked ? total + b.precio : total + 0;
              }, 0),
          }
        : item
    );
    setCarrito(arrayAdd);
    console.log(arrayAdd);
  };

  //Agregar productos al carrito
  const agregar = () => (prod) => {
    try {
      const nuevoCarrito = {
        key: prod.key,
        title: prod.title,
        thumbnail: prod.thumbnail,
        price: prod.price,
        cantidad: 1,
        extras: [],
        totalExtrasPrecio: prod.price,
        grupo: prod.grupo,
        tipo: prod.tipo,
        unidadTipo: prod.unidadTipo,
        factor: 1,
        precioReal: prod.price,
        listaPersonalizada: prod.listaPersonalizada,
      };

      const yaExiste = carrito.findIndex((item) => item.key === prod.key);

      if (yaExiste < 0) {
        setCarrito([...carrito, { id: prod.key, ...nuevoCarrito }]);
      } else {
        const arrayAdd = carrito.map((item) =>
          item.key === prod.key
            ? {
                key: prod.key,
                title: prod.title,
                thumbnail: prod.thumbnail,
                price: item.price,
                cantidad: item.cantidad + 1,
                extras: item.extras,
                totalExtrasPrecio: prod.price,
                grupo: prod.grupo,
                tipo: prod.tipo,
                unidadTipo: item.unidadTipo,
                factor: prod.factor,
                precioReal: prod.price,
                listaPersonalizada: prod.listaPersonalizada,
              }
            : item
        );
        setCarrito(arrayAdd);
      }
      setHeightCard(heightCard);
      handleClick({
        vertical: "top",
        horizontal: "center",
        tipo: "success",
        mensaje: "¡Añadido al carrito!",
      });
      console.log("+++++++++++++++++++++++++++");
      carrito.map((item) => console.log(item));
    } catch (error) {
      console.log(error);
    }
  };

  const actualizarCarrito = () => (value) => {
    console.log(value);
    setCarrito(value);
  };

  const actualizarPrecioProductos = () => (id, factor) => {
    dispatch(actualizarPrecioProductosAccion(productos, id, factor));
  };

  //Aumentar cantidad del producto
  const aumentar = () => (prod) => {
    try {
      const arrayAdd = carrito.map((item) =>
        item.key === prod.key
          ? {
              key: prod.key,
              title: prod.title,
              thumbnail: prod.thumbnail,
              price: prod.price,
              cantidad: item.cantidad + 1,
              extras: item.extras,
              grupo: prod.grupo,
              tipo: prod.tipo,
              unidadTipo: prod.unidadTipo,
              factor: item.factor,
              precioReal: prod.price,
              listaPersonalizada: prod.listaPersonalizada,
            }
          : item
      );
      setCarrito(arrayAdd);
    } catch (error) {
      console.log(error);
    }
  };

  //Se abre el drawer lateral y creamos los datos para construirlo
  const drawerOpen = () => (value) => {
    console.log(value);
    console.log(extras);
    setDrawerProducto(value);
    const yaExiste = carrito.findIndex((item) => item.key === value.key);
    if (yaExiste >= 0) {
      const arrayFiltrado = carrito.filter((item) => item.key === value.key);
      if (arrayFiltrado[0].extras.length > 0) {
        setExtrasLoad(arrayFiltrado[0].extras);
      } else {
        const arrtemp = extras.map((item) => {
          return {
            key: item.key,
            nombre: item.nombre,
            isChecked: false,
            precio: item.precio,
            grupo: item.grupo,
          };
        });
        setExtrasLoad(arrtemp);
      }
    }
    console.log(extrasLoad);
    setOpenDrawer(true);
  };

  //Eliminar productos al carrito
  const eliminar = () => (key) => {
    try {
      const arrayFiltrado = carrito.filter((item) => item.key !== key);
      setCarrito(arrayFiltrado);
      handleClick({
        vertical: "top",
        horizontal: "center",
        tipo: "error",
        mensaje: "¡Eliminado del carrito!",
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Disminuir en catidad productos en el carrito
  const disminuir = () => (prod) => {
    try {
      if (prod !== undefined) {
        if (prod.cantidad < 2) {
          const arrayFiltrado = carrito.filter((item) => item.key !== prod.key);
          setCarrito(arrayFiltrado);
          return;
        }
        const arrayAdd = carrito.map((item) =>
          item.key === prod.key
            ? {
                key: prod.key,
                title: prod.title,
                thumbnail: prod.thumbnail,
                price: item.price,
                cantidad: item.cantidad - 1,
                extras: item.extras,
                grupo: item.grupo,
                tipo: prod.tipo,
                unidadTipo: prod.unidadTipo,
                factor: item.factor,
                precioReal: prod.precioReal,
                listaPersonalizada: prod.listaPersonalizada,
              }
            : item
        );
        setCarrito(arrayAdd);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Total Valor del carrito
  const total = carrito.reduce(function (tot, arr) {
    return (
      tot +
      arr.cantidad *
        (Number(arr.price) +
          arr.extras.reduce(function (total, b) {
            return b.isChecked ? total + b.precio : total + 0;
          }, 0))
    );
  }, 0);

  //Total cantidad de productos en el carrito
  const totalProductos = carrito.reduce(function (tot, arr) {
    return tot + arr.cantidad;
  }, 0);

  //Extras
  const wsExtras = (arr) => {
    var ext = "";
    if (arr.length > 0) {
      ext = `%0A     _Extras:_`;
      arr.forEach((i) => {
        ext += i.isChecked
          ? `%0A      _${i.nombre} - ${clienteDB.moneda}: ${i.precio}_`
          : "";
      });
    }
    return ext;
  };

  //Crear el URL para el api de  Whatsapp
  function urlws() {
    console.info(comprador);
    console.log(clienteDB.costo_delivery);
    analytics.logEvent("begin_checkout", {
      shop: nameURL,
    });
    var formatter = new Intl.NumberFormat("es-ES");

    const circuloVerde = "%F0%9F%9F%A2%20";

    const listado = "%F0%9F%A7%BE%20";
    const bullet = "%E2%80%A3";
    const scooter = "%F0%9F%9B%B5%20";

    setError({ ...error, activo: false, mensaje: null });

    if (comprador.nombre === null) {
      setError({ ...error, activo: true, mensaje: "Debe indicar un nombre" });
      console.log(error);
      handleClick({ vertical: "top", horizontal: "center" });

      return;
    }
    if (comprador.docidentidad === null && clienteDB.nro_documento === "si") {
      setError({
        ...error,
        activo: true,
        mensaje: "Debe indicar un Nro de Documento de identidad",
      });
      console.log(error);
      handleClick({ vertical: "top", horizontal: "center" });

      return;
    }
    if (comprador.telefono === null && clienteDB.numero === "si") {
      setError({
        ...error,
        activo: true,
        mensaje: "Debe indicar un Nro de Teléfono",
      });
      console.log(error);
      handleClick({ vertical: "top", horizontal: "center" });

      return;
    }

    if (comprador.pickup) {
      if (comprador.direccion === null && clienteDB.sector === "si") {
        setError({
          ...error,
          activo: true,
          mensaje: "Debe colocar la dirección",
        });
        handleClick({ vertical: "top", horizontal: "center" });
        console.log(error);
        return;
      }
    }

    //Mostrar unidad seleccionada del producto par enviar al Whatsapp
    function UnidadTipoProducto(tipo, unidadTipo) {
      if (tipo === "Peso") {
        switch (unidadTipo) {
          case 0:
            return " (250grs) ";

          case 1:
            return " (500grs) ";

          case 2:
            return " (1Kg) ";
          default:
            return "";
        }
      } else if (tipo !==null) {
        return ` (${tipo}: ${unidadTipo})`;
      }
      else {
        return "";
      }
    }

    var url =
      "https://api.whatsapp.com/send?phone=" +
      clienteDB.telefono +
      "&text=*" +
      encodeURIComponent(clienteDB.empresa) +
      "*%0A%0A" +
      circuloVerde +
      "*Nuevo%20pedido* ID: " +
      nanoid(8);

    if (comprador.pickup) {
      url += "%0A%0A Delivery " + scooter;
    } else {
      url += "%0A%0A Pickup ";
    }
    if (clienteDB.nro_documento === "si") {
      url +=
        "%0A *Doc. de identidad:* " +
        encodeURIComponent(comprador.docidentidad);
    }
    url += "%0A *Cliente:* " + encodeURIComponent(comprador.nombre);
    if (clienteDB.numero === "si") {
      url += "%0A *Teléfono:* " + encodeURIComponent(comprador.telefono);
    }
    if (comprador.instagram.trim()) {
      url += "%0A *Instagram:* " + encodeURIComponent(comprador.instagram);
    }
    comprador.pickup &&
      (url += "%0A *Dirección:* " + encodeURIComponent(comprador.direccion)); //.replace("&","%26") )
    url += "%0A%0A" + listado + " *Pedido:* ";
    carrito.map(
      (item) =>
        (url +=
          "%0A" +
          bullet +
          " " +
          item.cantidad +
          UnidadTipoProducto(item.tipo, item.unidadTipo) +
          " x " +
          encodeURIComponent(item.title) +
          wsExtras(item.extras) +
          "%0A   " +
          clienteDB.moneda +
          ": " +
          formatter.format(
            item.cantidad *
              (Number(item.price) +
                item.extras.reduce(
                  (total, b) => (b.isChecked ? total + b.precio : total + 0),
                  0
                ))
          ))
    );
    if (comprador.pickup && delivery > 0) {
      url +=
        "%0D%0A%0ASubtotal:%20 " +
        clienteDB.moneda +
        " " +
        formatter.format(total);
      url +=
        "%0D%0ADelivery:%20 " +
        clienteDB.moneda +
        " " +
        formatter.format(delivery);
      url +=
        "%0D%0A*Total a Pagar:*%20 " +
        clienteDB.moneda +
        " " +
        formatter.format(delivery + total);
    } else {
      url +=
        "%0D%0A%0A*Total a Pagar:*%20 " +
        clienteDB.moneda +
        " " +
        formatter.format(total);
    }

    if (comprador.comentarios != null) {
      url +=
        "%0A%0A Comentarios Adicionales: " +
        encodeURIComponent(comprador.comentarios);
    }

    url +=
      "%0A" +
      "%0A *Información de pago para el cliente* " +
      "%0A " +
      encodeURIComponent(comprador.formaPago.tipo);
    if (comprador.formaPago.tipo !== null) {
      if (comprador.formaPago.nombreBanco !== null) {
        url += "%0A " + encodeURIComponent(comprador.formaPago.nombreBanco);
      }
      if (comprador.formaPago.cuenta !== null) {
        url += "%0A " + encodeURIComponent(comprador.formaPago.cuenta);
      }
      if (comprador.formaPago.titular !== null) {
        url += "%0A " + encodeURIComponent(comprador.formaPago.titular);
      }
      if (comprador.formaPago.cedula !== null) {
        url += "%0A " + encodeURIComponent(comprador.formaPago.cedula);
      }
    }

    url += "%0A Pedido a través de whatspidelo.com";

    localStorage.setItem("Whatspidelo", JSON.stringify(comprador));

    window.open(url, "_blank");
    //window.location = url
  }

  //Eliminar productos al carrito
  const buscar = () => (term) => {
    //e.preventDefault()
    try {
      dispatch(buscarProductosAccion(productos, term));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid>
      {
        //Verica que la url contenga la el parametro key, de no existir arroja  mensaje
        errorCliente ? (
          <Grid id="notfound">
            <Grid className="notfound">
              <Grid className="notfound-404">
                <h1>Ups!</h1>
                <h2>{errorMsjCliente}</h2>
              </Grid>
              <Grid container>
                {nameURL && (
                  <Grid item xs={12}>
                    <Link
                      href={"https://shop.whatspidelo.com/" + nameURL}
                      onClick={() => preventDefault()}
                    >
                      Reintentar
                    </Link>
                  </Grid>
                )}
                {/* <Grid item xs={nameURL ? 6 : 12}>
                  <Link
                    href="https://whatspidelo.com/"
                    onClick={() => preventDefault()}
                  >
                    Ir a WhatsPidelo.com
                  </Link>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        ) : //Esperamos que carguen primero los datos del cliente
        loading ? (
          <Backdrop
            className={classes.backdrop}
            open={loadingCliente && loading}
          >
            {!clienteDB.photoURL ? (
              <Grow
                in={true}
                {...(!clienteDB.photoURL ? { timeout: 2000 } : {})}
              >
                <Paper elevation={0} className={classes.paperProgress}>
                  <CardMedia
                    className={classes.mediaMarca}
                    image="logoWhatspidelo_2.png"
                  />

                  <img
                    src="loading.gif"
                    alt="Cargando"
                    style={{ width: "40px", marginTop: "10px" }}
                  />
                </Paper>
              </Grow>
            ) : (
              <Grow in={true} {...{ timeout: 2000 }}>
                <Paper elevation={0} className={classes.paperProgress}>
                  <CardMedia
                    className={classes.mediaPhone}
                    image={clienteDB.photoURL}
                  />
                  <Typography
                    variant="initial"
                    style={{
                      PaddingTop: "10px",
                      color: "white",
                      paddingTop: "8px",
                      fontWeight: 600,
                    }}
                  >
                    Powered by
                  </Typography>
                  <CardMedia
                    className={classes.mediaMarca}
                    image="logoWhatspidelo_2.png"
                  />
                  <img
                    src="loading.gif"
                    alt="Cargando"
                    style={{ width: "40px", marginTop: "10px" }}
                  />
                </Paper>
              </Grow>
            )}
          </Backdrop>
        ) : (
          //Estructura  completa de la pagina
          <Grid>
            {licenciaValida === false && (
              <Backdrop className={classes.backdrop} open={true}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  <h4>
                    {" "}
                    La tienda <strong>{clienteDB.empresa}</strong> no se
                    encuentra activa.
                  </h4>
                </Typography>
              </Backdrop>
            )}
            {clienteDB && (
              <Box>
                <Grid container spacing={1}>
                  {/* Head con datos del cliente */}
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`${clienteDB.empresa} - Tienda en linea`}</title>
                    <link
                      rel="canonical"
                      href={`http:/shop.whatspidelo.com/${clienteDB.nameURL}`}
                    />
                  </Helmet>
                  <Grid direction="column" container item xs={12}>
                    <ClienteOwn clienteDB={clienteDB} />
                  </Grid>

                  <Grid
                    item
                    className={classes.grid}
                    style={{ margin: "auto" }}
                  >
                    <ProductosCategorias
                      productos={productos}
                      loading={loading}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      md: "80%",
                    },
                  }}
                  margin="auto"
                >
                  <Grid container spacing={1}>
                    <Grid container spacing={1} xs={12} md={8}>
                      <Grid item className={classes.grid} xs={12}>
                        {error.activo ? (
                          <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            key={vertical + horizontal}
                            open={open}
                            autoHideDuration={2000}
                            onClose={handleClose}
                          >
                            <MuiAlert onClose={handleClose} severity="error">
                              {error.mensaje}
                            </MuiAlert>
                          </Snackbar>
                        ) : (
                          //Info que se ha agregado un producto al carrito
                          <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            key={vertical + horizontal}
                            open={open}
                            autoHideDuration={2000}
                            onClose={handleClose}
                          >
                            <MuiAlert
                              onClose={handleClose}
                              variant="filled"
                              severity={tipo}
                            >
                              {mensaje}
                            </MuiAlert>
                          </Snackbar>
                        )}

                        {/*  Componente de lista de productos */}
                        <ProductosList
                          productos={productos}
                          carrito={carrito}
                          actualizarCarrito={actualizarCarrito()}
                          actualizarPrecioProductos={actualizarPrecioProductos()}
                          agregar={agregar()}
                          disminuir={disminuir()}
                          unidadTipos={unidadTipos}
                          setUnidadTipos={(e) => setUnidadTipos()}
                          loading={loading}
                          moneda={clienteDB.moneda}
                          setOpenDrawer={drawerOpen()}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={4}
                      className={classes.secondColumn}
                      alignItems="stretch"
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Card
                          style={{
                            display: "block",
                            transitionDuration: "0.3s",
                            height: `${heightCard}`,
                          }}
                        >
                          <Grid item container className={classes.grid} xs={12}>
                            {/*   //Componente del carrito de compras, recibe de props el carrito y las funciones para gestionar los productos añadidos */}
                            <Carrito
                              carrito={carrito}
                              aumentar={aumentar()}
                              eliminar={eliminar()}
                              disminuir={disminuir()}
                              actualizarCarrito={actualizarCarrito()}
                              actualizarPrecioProductos={actualizarPrecioProductos()}
                              total={total}
                              moneda={clienteDB.moneda}
                              tasa={clienteDB.tasa}
                              delivery={comprador.pickup}
                              costo_delivery={delivery}
                              sector={clienteDB.sector}
                              setOpenDrawer={drawerOpen()}
                            />
                          </Grid>
                          <Grid item container className={classes.grid} xs={12}>
                            {/* Formas de Pago */}
                            <FormasPago formasDePago={formaPagos} />
                          </Grid>
                          <Grid item container className={classes.grid} xs={12}>
                            {/* Datos del comprador */}
                            <Cliente
                              datosCLiente={clienteDB}
                              deliveries={deliveries}
                              delivery={delivery}
                              setDelivery={setDelivery}
                              moneda={clienteDB.moneda}
                              carritoLength={carrito.length}
                            />
                          </Grid>
                          <Grid item container className={classes.grid}>
                            {carrito.length > 0 && (
                              <Button
                                className={classes.boton}
                                variant="contained"
                                startIcon={<WhatsAppIcon />}
                                fullWidth={true}
                                onClick={() =>
                                  urlws()
                                } /* Al hacer clic llama a la fucion para generar el url del api de whatsapp */
                              >
                                Enviar a Whatsapp
                              </Button>
                            )}
                          </Grid>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={1}
                    xs={12}
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      maxHeight: "30px",
                    }}
                  >
                    {/* Footer */}
                    <Paper
                      className={classes.paper}
                      elevation={0}
                      style={{ textAlign: "center", width: "100%" }}
                    >
                      <Typography variant="subtitle1" color="primary">
                        <Link
                          href="https://shop.whatspidelo.com/a/addClient/web"
                          onClick={() => preventDefault()}
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            textDecoration: "none",
                          }}
                        >
                          Prueba 14 días gratis tu tienda WhatsPidelo aquí
                        </Link>
                      </Typography>
                    </Paper>
                    <AppCategoriesBar productos={productos} loading={loading} />
                  </Grid>
                </Box>
                {/* NavBar de carrito con resumen de propductos añadidos y el total */}

                <NavBar
                  carrito={totalProductos}
                  total={total}
                  delivery={delivery}
                  moneda={clienteDB.moneda}
                  buscar={buscar()}
                />
              </Box>
            )}
          </Grid>
        )
      }
      <DrawerExtras
        open={openDrawer}
        setOpenDrawer={setOpenDrawer}
        producto={drawerProducto}
        moneda={clienteDB.moneda}
        addAdicionales={addAdicionales}
        extras={extras}
        extrasLoad={extrasLoad}
        setExtrasLoad={setExtrasLoad}
      />
    </Grid>
  );
};

export default Contenedor;
