import { useGradientAvatarStyles } from "@mui-treasury/styles/avatar/gradient";

export default ({ palette, breakpoints}) => {
  const space = 10;
  return {
    root: {
      minWidth: 256,
      backgroundColor: "#4caf50",
      padding: 10,
       borderRadius: "5px 5px 2px 2px"
    },
    header: {
      padding: `4px ${space}px 0`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      width: 70,
      height: 70,
    },
    divider: {
      [breakpoints.up("sm")]: {
        marginBottom: 30 - 1, // minus 1 due to divider height
      },
    },
    extra: {
      //textTransform: "uppercase",
      fontSize: 18,
      color: "white",
      letterSpacing: "1px",
      fontWeight: "700",
      marginLeft: "10px",
      lineHeight: "2.3em"
    },
    horario: {
      textAlign: "left",
      fontSize: 10,
      paddingRight: "10px",
      color: "white",
      //color: palette.grey[500],
    },
    iconos: {
      marginLeft: "7px",
      marginBottom: "5px",
    },
  };
};
