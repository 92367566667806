import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import {
  nuevoVendedorAccion,
  obtenerVendedoresAccion,
  eliminarVendedorAccion,
} from "../redux/VendedorDucks";
import { useSelector, useDispatch } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 50,
  },
  formulario: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
}));

const Vendedores = () => {
  const dispatch = useDispatch();
  const vendedores = useSelector((store) => store.vendedores.results);
  const loading = useSelector((store) => store.vendedores.loading);
  const typeLoading = useSelector((store) => store.vendedores.typeLoading);

  const vendorIni = {
    nombre: "",
    email: "",
  };

  const [vendedor, setVendedor] = React.useState(vendorIni);

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        dispatch(obtenerVendedoresAccion());
      } catch (error) {
        console.log(error);
      }
    };

    obtenerDatos();
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid md={8}>
          <Typography variant="h5" color="initial">
            Vendedores
          </Typography>
          <Divider />
          {loading && typeLoading === "eliminar" && (
            <LinearProgress color="secondary" />
          )}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código</StyledTableCell>
                  <StyledTableCell>Vendedor</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendedores.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.cod_vendor}</TableCell>
                    <TableCell>{item.nombre}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) =>
                          dispatch(eliminarVendedorAccion(item.id))
                        }
                      >
                        Borrar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid md={4}>
          <Typography variant="h5" color="initial">
            Registrar vendedor
          </Typography>
          <Divider />
          <form className={classes.formulario}>
            <Grid item>
              <TextField
                id=""
                label="Nombre del Vendedor"
                variant="outlined"
                onChange={(e) =>
                  setVendedor({ ...vendedor, nombre: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id=""
                label="email"
                variant="outlined"
                onChange={(e) =>
                  setVendedor({ ...vendedor, email: e.target.value })
                }
                type="email"
                fullWidth
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => dispatch(nuevoVendedorAccion(vendedor))}
            >
              {loading && typeLoading === "nuevo" ? (
                <CircularProgress />
              ) : (
                <Typography variant="subtitle2">Crear</Typography>
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Vendedores;
