import React from "react";
import { createLicense } from "license-key-gen";

const CrearLicencia = () => {
  const userInfo = {
    sheet_id: "sdasdasdsd",
    creado: "02/07/2020",
    vencimiento: "02/07/2021",
  };

  const licenseData = {
    info: userInfo,
    prodCode: "whatspidelo_anual",
    appVersion: "1",
    osType: "IOS8",
  };

  const addLicense = () => {
    var l = createLicense(licenseData);
    console.log("aca: " + l.license);
  };

  return <div>{addLicense()}</div>;
};

export default CrearLicencia;
