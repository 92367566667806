import { db } from "../firebase";
import { createLicense, validateLicense } from "license-key-gen";
import moment from "moment";
import "moment/locale/es";
import sendMail from "./Mail";
import { format } from "date-fns";

//Constantes

const dataInicial = {
  loading: false,
  typeLoading: null,
  status: null,
  results: [],
};

//types
const LOADING = "LOADING";
const NUEVA_LICENCIA_EXITO = "NUEVA_LICENCIA_EXITO";
const BORRAR_LICENCIA_EXITO = "BORRAR_LICENCIA_EXITO";
const LICENCIA_EXITO = "LICENCIA_EXITO";
const VALIDAR_LICENCIA_EXITO = "VALIDAR_LICENCIA_EXITO";
const LICENCIA_ERROR = "LICENCIA_ERROR";

//Reducers

export default function licenciaReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true, typeLoading: action.payload };
    case LICENCIA_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "leer",
        results: [...action.payload],
      };
    case NUEVA_LICENCIA_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "nuevo",
        results: [...action.payload],
      };
    case BORRAR_LICENCIA_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "eliminar",
        results: [...action.payload],
      };
    case VALIDAR_LICENCIA_EXITO:
      return {
        ...state,
        loading: false,
        typeLoading: "validar",
        status: action.payload,
      };
    case LICENCIA_ERROR:
      return { ...state, loading: false, status: false };
    default:
      return state;
  }
}

//Acciones

export const obtenerLicenciasAccion = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: "leer",
  });
  try {
    const data = await db.collection("licencias").get();
    const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    dispatch({
      type: LICENCIA_EXITO,
      payload: results,
    });
  } catch (error) {
    console.log(error);
  }
};

export const nuevaLicenciaAccion = (nuevaLicencia) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: LOADING,
    payload: "nuevo",
  });
  try {
    const clienteInfo = {
      cliente_id: nuevaLicencia.sheetID,
      //inicio: moment(nuevaLicencia.fecha_ini.toDate()).format("L"),
      vencimiento: moment(nuevaLicencia.fecha_fin.toDate()).format("L"),
    };

    const licenseData = {
      info: clienteInfo,
      prodCode: "whatspidelo_anual",
      appVersion: "1",
      osType: "IOS8",
    };
    const lic = createLicense(licenseData);

    await db
      .collection("licencias")
      .doc(nuevaLicencia.sheetID)
      .set({
        ...nuevaLicencia,
        fecha_ini: moment(nuevaLicencia.fecha_ini).toDate(),
        fecha_fin: moment(nuevaLicencia.fecha_fin).toDate(),
        license_nro: lic.license,
        created_at: moment().toDate(),
      });

    const clienteDB = await db
      .collection("clientes")
      .doc(nuevaLicencia.sheetID)
      .get();
    const cliente = clienteDB.data();

    const nombre = cliente.nombre + " " + cliente.apellido;
    let message = {
      nameUrl: cliente.nameURL,
      sheetGoogle: cliente.sheetId,
      licencia: lic.license,
      fecha_hasta: moment(nuevaLicencia.fecha_fin).format("LL"),
    };
    sendMail(nombre, cliente.email, message, 5);

    const data = await db.collection("licencias").get();

    const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({
      type: LICENCIA_EXITO,
      payload: results,
    });
  } catch (error) {
    console.log(error);
  }
};

export const eliminarLicenciaAccion = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: "eliminar",
  });
  try {
    //const vendedorDB = await db.collection('vendedores').doc(short).get()

    await db.collection("licencias").doc(id).delete();
    const data = await db.collection("licencias").get();
    const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({
      type: LICENCIA_EXITO,
      payload: results,
    });
  } catch (error) {}
};

//Validacion de la licencia
export const validarLicenciaAccion = (SheetID) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: LOADING,
    payload: "BUSCAR",
  });
  try {
    const licenciaDemo = "H72J2MS2HAX971MHS";
    const clienteDB = await db.collection("clientes").doc(SheetID).get();
    if (clienteDB.exists) {
      //Validar si el cliente usa la licencia demo y tiene menos de 15 dias de creado
      const cliente = clienteDB.data();

      //Valido si es cliente con licencia demo
      if (cliente.licencia === licenciaDemo) {
        //Retorna true si aun se encuetra en el periodo de los 15 dias de prueba
        const status = moment(cliente.created_at)
          .add(15, "days")
          .isAfter(new Date());
        dispatch({
          type: VALIDAR_LICENCIA_EXITO,
          payload: status,
        });
      } else {
        //Es un cliente con licencia creada
        const licenciaDB = await db.collection("licencias").doc(SheetID).get();
        if (licenciaDB.exists) {
          const license = licenciaDB.data();
          //Creacion de la estructura para verificacion de la licencia
          const clienteInfo = {
            cliente_id: cliente.sheetId,
            /* inicio: moment(license.fecha_ini.toDate()).format("L"), */
            vencimiento: moment(license.fecha_fin.toDate()).format("L"),
          };
          
          const licenseData = {
            info: clienteInfo,
            prodCode: "whatspidelo_anual",
            appVersion: "1",
            osType: "IOS8",
          };
          console.log ('*************** LA LICENCIA VALIDATE ************************');
          //console.log (validateLicense(licenseData, cliente.licencia));
          console.log (moment(license.fecha_fin.toDate()).format("L"));
          console.log(moment(license.fecha_fin.toDate()).isBefore(
            moment(new Date()).format("L")
          ) );
          //Se valida que la licencia se la correcta para el cliente y el periodo seleccionado
          //const resultResult = validateLicense(licenseData, cliente.licencia);

          //Validacion del estatus de la licencia, que no este vencida

          /* console.log(moment(license.fecha_fin.toDate()).format("L") );
          console.log( moment(new Date()).format("L") );
          console.log(moment(license.fecha_fin.toDate()).format("L") > moment(new Date()).format("L") );
          console.log(moment(license.fecha_fin.toDate()).isAfter(
            moment(new Date()).format("L")
          ) );
 */
          /* const fecha = moment(license.fecha_fin.toDate()).format("L");  
          const fechaActual = moment(); */
          const statusLic = true; /* moment().format("L").isBefore(
            fecha
          ) ;  */

          
          /* console.log(fecha);
          console.log(moment().format("L"));
          console.log(statusLic);
          console.log(fecha > moment(new Date()).format("L") );
          console.log(fechaActual.diff(fecha, 'days')); */
          /* const statusLic = moment(license.fecha_fin.toDate()).isAfter(
            moment(new Date()).format("L")
          ); */ // true: Licencia Vigente o False: Licencia Vencida

          dispatch({
            type: VALIDAR_LICENCIA_EXITO,
            payload: statusLic,
          });
        } else {
          dispatch({
            type: LICENCIA_ERROR,
          });
        }
      }
    } else {
      dispatch({
        type: LICENCIA_ERROR,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: LICENCIA_ERROR,
      payload: false,
    });
  }
};
