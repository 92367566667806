import { db } from "../firebase";
import moment from "moment";
import "moment/locale/es";

//Constantes

const dataInicial = {
    loading: false,
    typeLoading: null,
    results: [],
};

//types
const LOADING = "LOADING";
const PROSPECTO_EXITO = "NUEVO_PROSPECTO_EXITO";

//Reducers

export default function prospectosReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return {...state, loading: true, typeLoading: action.payload };
        case PROSPECTO_EXITO:
            return {
                ...state,
                loading: false,
                typeLoading: "leer",
                results: [...action.payload],
            };
        default:
            return state;
    }
}

//Acciones

export const obtenerProspectosAccion = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: "leer",
    });
    try {
        const data = await db.collection("prospectos").get();
        const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const vendedorGet = await db.collection("vendedores").get();
        const vendedorResults = vendedorGet.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        var vendedor = [];
        vendedorResults.map((item) => {
            vendedor = [
                ...vendedor,
                {
                    cod_vendor: item.cod_vendor,
                    nombre: item.nombre,
                },
            ];
        });

        var prospectos = [];
        results.map(async(item) => {
            let fecha;
            if (item.created_at) {
                let creado = moment(item.created_at);
                fecha = creado.format("L");
            } else {
                fecha = "";
            }

            let vendedorNombre = '';
            vendedor.forEach((element) => {
                if (element.cod_vendor === item.vendedor_id) {
                    vendedorNombre = element.nombre;
                    return false;
                }
            });

            prospectos = [
                ...prospectos,
                {
                    id: item.id,
                    nombre: item.nombre,
                    apellido: item.apellido,
                    email: item.email,
                    telefono: item.telefono,
                    //como: item.como,
                    contactar: item.contactar,
                    tienda: item.nameURL,
                    //vendedor: item.vendedor_id,
                    vendedor: vendedorNombre,
                    fecha: fecha,
                    filter: true,
                    fechaord: item.created_at
                },
            ];
        });

        prospectos.sort(function(a, b) {
            if (a.fechaord < b.fechaord) {
                return 1;
            }
            if (a.fechaord > b.fechaord) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        dispatch({
            type: PROSPECTO_EXITO,
            payload: prospectos,
        });
    } catch (error) {
        console.log(error);
    }
};