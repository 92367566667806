import { db } from "../firebase";
import moment from "moment";
import "moment/locale/es";
import * as creds from "../creds/check_license.json";

//Constantes

const dataInicial = {
    loading: false,
    typeLoading: null,
    results: [],
};

//types
const LOADING = "LOADING";
const CLIENTES_EXITO = "NUEVO_CLIENTES_EXITO";
export var demovencido = 0;
export var demoactivo = 0;

//Reducers

export default function clientesReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return {...state, loading: true, typeLoading: action.payload };
        case CLIENTES_EXITO:
            return {
                ...state,
                loading: false,
                typeLoading: "leer",
                results: [...action.payload],
            };
        default:
            return state;
    }
}

//Acciones

var cantidadPrueba = (vencido, endemo) => {
    demovencido = vencido
    demoactivo = endemo
}

export const obtenerClientesAccion = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: "leer",
    });
    try {
        const licenciasGet = await db.collection("licencias").get();
        const licenciasResults = licenciasGet.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        var licencias = [];
        licenciasResults.map((item) => {
            licencias = [
                ...licencias,
                {
                    id: item.id,
                    fecha_ini: item.fecha_ini,
                    fecha_fin: item.fecha_fin,
                    filter: true,
                },
            ];
        });

        const data = await db.collection("clientes").get();
        const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        var vencidos = 0;
        var demo = 0;
        var paso = 0;
        var clientes = [];
        results.map(async(item) => {
            let creado = moment(item.created_at);
            let transcurrido = moment().diff(creado, "days"); // calculo la diferencia de dias desde hoy hasta el vencimiento
            var dif, hasta, fdesde;
            let color;
            console.log(paso);

            dif = creds.diasMaximoDemo - transcurrido;
            fdesde = creado.format("L");
            hasta = creado.add(14, "days").format("L");

            let boton = true;
            if (dif >= 1 && dif <= 5) {
                color = "#f0ad4e";
            } else if (dif < 1) {
                color = "#D3662E";
                boton = false;
            } else {
                color = "";
            }

            if (item.licencia === creds.licenceDemo) {
                if ((dif >= 1) && (dif <= creds.diasMaximoDemo)) {
                    demo++;
                } else {
                    vencidos++;
                }

                let nombre = ((item.apellido) && (item.nombre)) ? item.nombre + ' ' + item.apellido : 'No registrado';
                clientes = [
                    ...clientes,
                    {
                        id: item.id,
                        empresa: item.empresa,
                        email: item.email,
                        desde: fdesde,
                        hasta: hasta,
                        diferencia: dif,
                        colorback: color,
                        filter: true,
                        showboton: boton,
                        enlace: item.nameURL,
                        nombre,
                        telefono: item.telefono,
                        hoja: item.sheetId
                    },
                ];
            }

        });

        clientes.sort(function(a, b) {
            if (a.diferencia < b.diferencia) {
                return 1;
            }
            if (a.diferencia > b.diferencia) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });


        cantidadPrueba(vencidos, demo);

        dispatch({
            type: CLIENTES_EXITO,
            payload: clientes,
        });
    } catch (error) {
        console.log(error);
    }
};