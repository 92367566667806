var React = require("react");
var QRCode = require("qrcode.react");

class QrCustom extends React.Component {
  render() {
    return (
      <QRCode
        value={this.props.url}
        size={190}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
        renderAs={"svg"}
        imageSettings={{
          src: "https://i.postimg.cc/QdtDvqZP/mini-Logo-Monchegal.png",
          x: null,
          y: null,
          height: 24,
          width: 24,
          excavate: true,
        }}
      />
    );
  }
}

export { QrCustom };
