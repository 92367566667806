import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'

 // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyD7quh3xo7mdkWkNJ34wv5a7YfkfKU8wd4",
    authDomain: "whatspidelo.firebaseapp.com",
    databaseURL: "https://whatspidelo.firebaseio.com",
    projectId: "whatspidelo",
    storageBucket: "whatspidelo.appspot.com",
    messagingSenderId: "123932231063",
    appId: "1:123932231063:web:d31b37e256df9fc096b0d2",
    measurementId: "G-LX6H0G2GM4"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const auth =  firebase.auth()
  const db = firebase.firestore() 
  const storage = firebase.storage() 
  const analytics = firebase.analytics()

  export { auth, firebase, db, storage, analytics}