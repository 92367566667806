import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import LanguageIcon from "@material-ui/icons/Language";
import ErrorIcon from "@material-ui/icons/Error";
import {
  disponibilidadNombreURLAccion,
  registrarProspectoAccion,
} from "../redux/ClienteDucks";
import { useParams } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import YoutubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://whatspidelo.com/">
        WhatsPidelo.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://whatspidelo.com/wp-content/uploads/2020/09/img_registro-min.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: "100%",
  },
}));

export default function ClienteRegistro() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const disponible = useSelector((store) => store.cliente.disponible);
  const loading = useSelector((store) => store.cliente.loading);
  const errorCliente = useSelector((store) => store.cliente.error);
  const msjErrorCliente = useSelector((store) => store.cliente.msjerror);
  const { vendor } = useParams();

  const clienteInicial = {
    nombre: "",
    apellido: "",
    email: "",
    como: "",
    nameURL: "",
    telefono: "",
    vendedor_id: vendor,
  };

  const [cliente, setCliente] = React.useState(clienteInicial);
  const [errorNombre, setErrorNombre] = React.useState(false);
  const [errorApellido, setErrorApellido] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [confirmarEmail, setConfirmarEmail] = React.useState("");
  const [errorConfirmarEmail, setErrorConfirmarEmail] = React.useState(false);
  const [errorTelefono, setErrorTelefono] = React.useState(false);
  const [errorDisponible, setErrorDisponible] = React.useState(false);
  const [formulario, setFormulario] = React.useState(true);
  const [enviar, setEnviar] = React.useState(false);
  const [errorMsjDisponible, setErrorMsjDisponible] = React.useState("");

  React.useEffect(() => {
    if (disponible && enviar) {
      dispatch(registrarProspectoAccion(cliente));
      setFormulario(false);
      setEnviar(false);
    } else {
      if (!disponible && enviar && !loading) {
        setErrorMsjDisponible("url de tienda no disponible");
        setErrorDisponible(true);
      }
    }
  }, [dispatch, cliente, disponible, enviar, loading]);

  function nombreCliente(name) {
    if (!name.trim()) {
      setCliente({ ...cliente, nombre: "" });
    }
    if (validator.isAscii(name)) {
      setErrorNombre(false);
      setCliente({ ...cliente, nombre: name });
    } else {
      setErrorNombre(true);
    }
  }

  function apellidoCliente(name) {
    if (!name.trim()) {
      setCliente({ ...cliente, apellido: "" });
    }
    if (validator.isAscii(name)) {
      setErrorApellido(false);
      setCliente({ ...cliente, apellido: name });
    } else {
      setErrorApellido(true);
    }
  }

  function emailCliente(name) {
    if (!name.trim()) {
      setCliente({ ...cliente, email: "" });
    }
    if (validator.isEmail(name)) {
      setErrorEmail(false);
      setCliente({ ...cliente, email: name });
    } else {
      setErrorEmail(true);
      setCliente({ ...cliente, email: name });
    }
  }

  function confirmarEmailCliente(name) {
    if (validator.isEmail(name) && cliente.email === name) {
      setErrorConfirmarEmail(false);
      setConfirmarEmail(name);
    } else {
      setErrorConfirmarEmail(true);
      setConfirmarEmail(name);
    }
  }

  function telefonoCliente(name) {
    if (validator.isEmpty(name)) {
      setCliente({ ...cliente, telefono: null });
    }
    if (validator.isNumeric(name)) {
      setErrorTelefono(false);
      setCliente({ ...cliente, telefono: name });
    } else {
      setErrorTelefono(true);
      setCliente({ ...cliente, telefono: name });
    }
  }

  function nombreURL(name) {
    if (validator.isEmpty(name)) {
      setCliente({ ...cliente, nameURL: null });
    }
    if (validator.isAlphanumeric(name)) {
      setErrorDisponible(false);
      setErrorMsjDisponible("");
      setEnviar(false);
      setCliente({ ...cliente, nameURL: name });
    }
    if (name.length < 5) {
      setErrorDisponible(true);
    }
  }

  const enviarForm = () => {
    let error = false;
    if (!validator.isAscii(cliente.nombre)) {
      error = true;
      setErrorNombre(true);
    }
    if (!validator.isAscii(cliente.apellido)) {
      error = true;
      setErrorApellido(true);
    }
    if (!validator.isEmail(cliente.email)) {
      error = true;
      setErrorEmail(true);
    }
    if (!confirmarEmail.trim()) {
      error = true;
      setErrorConfirmarEmail(true);
    }
    if (confirmarEmail !== cliente.email) {
      error = true;
      setErrorConfirmarEmail(true);
      setErrorEmail(true);
    }
    if (cliente.telefono.trim() && !validator.isNumeric(cliente.telefono)) {
      error = true;
      setErrorTelefono(true);
    }
    if (!validator.isAlphanumeric(cliente.nameURL)) {
      error = true;
      setErrorDisponible(true);
    }
    if (cliente.nameURL.length < 5) {
      error = true;
      setErrorDisponible(true);
    }

    if (!error) {
      dispatch(disponibilidadNombreURLAccion(cliente.nameURL));
      setEnviar(true);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registro de Tienda
          </Typography>
          {formulario ? (
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errorNombre}
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="Nombre"
                    size="small"
                    autoFocus
                    value={cliente.nombre}
                    onChange={(e) => nombreCliente(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errorApellido}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Apellido"
                    name="lastName"
                    autoComplete="lname"
                    size="small"
                    value={cliente.apellido}
                    onChange={(e) => apellidoCliente(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errorDisponible}
                    variant="outlined"
                    required
                    fullWidth
                    id="shop"
                    label="Nombre para URL de la tienda"
                    name="shop"
                    autoComplete="shop"
                    size="small"
                    value={cliente.nameURL}
                    helperText={errorMsjDisponible}
                    onChange={(e) => nombreURL(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {!errorDisponible ? (
                            <LanguageIcon style={{ color: "#c4c4c4" }} />
                          ) : (
                            <ErrorIcon color="secondary" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errorEmail}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    size="small"
                    autoComplete="email"
                    value={cliente.email}
                    onChange={(e) => emailCliente(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errorConfirmarEmail}
                    variant="outlined"
                    required
                    fullWidth
                    id="validate_email"
                    label="Confirmar Email"
                    name="validate_email"
                    onChange={(e) => confirmarEmailCliente(e.target.value)}
                    autoComplete="email"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errorTelefono}
                    variant="outlined"
                    fullWidth
                    id="phone"
                    label="Nro. Whatsapp"
                    name="phone"
                    autoComplete="phone"
                    size="small"
                    value={cliente.telefono}
                    onChange={(e) => telefonoCliente(e.target.value)}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      id=""
                      size="small"
                      fullWidth
                      style={{ marginLeft: 13 }}
                    >
                      {" "}
                      ¿Cómo te enteraste de nosotros?
                    </InputLabel>
                    <Select
                      labelId=""
                      id="demo-simple-select"
                      label="¿Cómo te enteraste de nosotros?"
                      variant="outlined"
                      style={{ padding: "4px" }}
                      fullWidth
                      size="small"
                      color="primary"
                      onChange={(e) =>
                        setCliente({ ...cliente, como: e.target.value })
                      }
                    >
                      <MenuItem value={"Publicidad"}>{"Publicidad"}</MenuItem>
                      <MenuItem value={"Recomendacion"}>
                        {"Recomendación"}
                      </MenuItem>
                      <MenuItem value={"Instagram"}>{"Instagram"}</MenuItem>
                      <MenuItem value={"Facebook"}>{"Facebook"}</MenuItem>
                      <MenuItem value={"Google"}>{"Google"}</MenuItem>
                      <MenuItem value={"Otro"}>{"Otro"}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => enviarForm()}
                disabled={!loading}
              >
                {!loading ? (
                  <Typography color="initial">
                    <CircularProgress
                      size="1rem"
                      style={{ color: "#efefef" }}
                    />{" "}
                    Validando
                  </Typography>
                ) : (
                  <Typography color="initial">
                    Iniciar creación de tienda
                  </Typography>
                )}
              </Button>
              <Box mt={3}>
                <Copyright />
              </Box>
            </form>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                {errorCliente ? (
                  <Typography variant="subtitle1" color="initial">
                    {msjErrorCliente}
                  </Typography>
                ) : (
                  <Grid>
                    <Typography variant="subtitle1" color="initial">
                      ¡Gracias por registrarte! Recibirás un correo con los
                      pasos para continuar la creación de tu tienda, también
                      puedes escribir ahora mismo a nuestro whatsapp y te
                      acompañaremos hasta completar el proceso.
                    </Typography>
                    <Link
                      variant="subtitle1"
                      href="https://api.whatsapp.com/send?phone=584126024271&text=Hola! He creado mi tienda en WhatsPïdelo."
                      target="_blank"
                    >
                      <WhatsAppIcon
                        fontSize="large"
                        color="#25d366"
                      ></WhatsAppIcon>
                      {"  "}Clic aquí para ir a nuestro Whatsapp
                    </Link>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} style={{ marginTop: "20px" }}>
                {
                  <Typography component="h1" variant="h6" align="center">
                    Síguenos en nuestras redes sociales<br></br>
                    <Link
                      color="inherit"
                      href="https://www.instagram.com/whatspidelo/"
                      target="_blank"
                    >
                      <InstagramIcon color="secondary"></InstagramIcon>
                    </Link>{" "}
                    <Link
                      color="inherit"
                      href="https://www.youtube.com/channel/UC1OXp42bPMUi2MRzyvsy_Kg"
                      target="_blank"
                    >
                      <YoutubeIcon color="secondary"></YoutubeIcon>
                    </Link>{" "}
                  </Typography>
                }
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
