import React from "react";
import {
  nuevoClienteAccion,
  disponibilidadNombreClienteAccion,
  contactarProspecto,
} from "../redux/ClienteDucks";
import { obtenerVendedorAccion } from "../redux/VendedorDucks";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import {
  Button,
  Grid,
  Paper,
  makeStyles,
  TextField,
  CircularProgress,
  Typography,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { ClienteDatos } from "./ClienteDatos";
import { useParams } from "react-router-dom";
import HelpIcon from "@material-ui/icons/Help";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { CardMedia, Modal } from "@material-ui/core";
import ComputerIcon from "@material-ui/icons/Computer";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    padding: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  paperModal: {
    position: "absolute",
    width: 400,
    paddingBottom: 90,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mediaMax: {
    height: 300,
    width: 600,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    marginTop: "100px",
  },
  mediaPhone: {
    width: "350px",
    height: "150px",
    position: "relative",
    marginTop: "160px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundSize: "contain",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export const NuevoCliente = () => {
  const dispatch = useDispatch();
  const clienteCreado = useSelector((store) => store.cliente.cliente);
  const errorCliente = useSelector((store) => store.cliente.error);
  const msjErrorCliente = useSelector((store) => store.cliente.msjerror);
  const loading = useSelector((store) => store.cliente.loading);
  const typeLoading = useSelector((store) => store.cliente.typeLoading);
  const disponible = useSelector((store) => store.cliente.disponible);
  const contactado = useSelector((store) => store.cliente.contactado);
  const urlWP = "https://shop.whatspidelo.com/"; //url de la tienda

  const { vendor } = useParams();
  const vendedor = useSelector((store) => store.vendedores.results);

  const cliente = {
    nombre: "",
    apellido: "",
    email: "",
    como: "",
    sheetId: "",
    nameURL: null,
    vendedor_id: null,
  };

  const [open, setOpen] = React.useState(false);
  const [imagenModal, setImagenModal] = React.useState({});

  const handleOpen = (img, type) => {
    setImagenModal({ ...imagenModal, url: img, type: type });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        dispatch(obtenerVendedorAccion(vendor));
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, [dispatch, vendor]);

  const [newCliente, setNewCliente] = React.useState(cliente);
  const [formulario, setFormulario] = React.useState(true);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorURL, setErrorURL] = React.useState(false);
  const [validateURL, setValidateURL] = React.useState(false);
  const [errorNameURL, setErrorNameURL] = React.useState(false);
  const [msgNameURL, setMsgNameURL] = React.useState(false);
  const [errorNombre, setErrorNombre] = React.useState(false);
  const [msgNombre, setMsgNombre] = React.useState(false);
  const [errorApellido, setErrorApellido] = React.useState(false);
  const [msgApellido, setMsgApellido] = React.useState(false);
  const [nextPaso, setNextPaso] = React.useState(false);
  const [botonDisponible, setBotonDisponible] = React.useState(true); //Boton de Disponibilidad

  const [buttonText, setButtonText] = React.useState(
    "Haz clic aquí y te contactaremos"
  );

  const changeText = (text) => setButtonText(text);

  const preventDefault = (event) => event.preventDefault();

  const classes = useStyles();

  function getSheetID(url) {
    var n = url.search("d/");
    var end = url.search("/edit");
    var res = url.substr(n + 2, end - n - 2);
    return res;
  }

  function datosCliente(obj) {
    console.log("OBJ", obj);
    setErrorEmail(false);
    setErrorURL(false);

    if (
      obj.nombre.length > 2 &&
      obj.apellido.length > 2 &&
      validator.isEmail(obj.email)
    ) {
      setNextPaso(true);
    } else {
      setNextPaso(false);
    }
    setNewCliente(obj);
  }

  const Validacion = (e) => {
    try {
      //e.preventDefault()

      var url = getSheetID(newCliente.sheetId);
      setErrorEmail(false);
      setErrorURL(false);

      setNewCliente({ ...newCliente, sheetId: url });
      newCliente.sheetId = url;
      if (!disponible) {
        setErrorNameURL(true);
        setMsgNameURL(
          "El nombre de tienda ya existe, escribe otro y comprueba su disponibilidad."
        );
        return;
      }
      /* console.log('argo:' + newCliente.email.length())
      if ( !  newCliente.email.trim() ) {
        setErrorEmail(true);
        return
      } */
      if (!validator.isEmail(newCliente.email)) {
        setErrorEmail(true);
        return;
      }
      if (url === undefined || !newCliente.sheetId.trim()) {
        setErrorURL(true);
        return;
      }

      newCliente.vendedor_id = vendedor.id;

      dispatch(nuevoClienteAccion(newCliente));

      setFormulario(false);
    } catch (error) {
      console.log(error);
    }
  };

  function valNameURL(name) {
    if (name.length < 5) {
      setBotonDisponible(true);
    } else {
      setBotonDisponible(false);
    }

    if (validator.isAlphanumeric(name)) {
      setValidateURL(false);
      setErrorNameURL(false);
      setNewCliente({ ...newCliente, nameURL: name });
      setMsgNameURL(null);
    } else {
      setErrorNameURL(true);
      setMsgNameURL("Solo se aceptan Números y letras");
    }
  }

  function esDisponible() {
    if (validator.isAlphanumeric(newCliente.nameURL)) {
      setValidateURL(true);
      setErrorNameURL(false);
      dispatch(
        disponibilidadNombreClienteAccion(
          newCliente.nameURL,
          newCliente.nombre,
          newCliente.apellido,
          newCliente.email,
          newCliente.como
        )
      );
    } else {
      setBotonDisponible(true);
      setErrorNameURL(true);
    }
  }

  function contactar() {
    try {
      changeText("Enviado, Pronto te escribiremos");
      dispatch(
        contactarProspecto(
          newCliente.email,
          newCliente.nombre,
          newCliente.apellido
        )
      );
    } catch (error) {
      changeText("Ups, error al enviar correo");
    }
  }

  function nombreCliente(name) {
    if (validator.isAlpha(name)) {
      setErrorNombre(false);
      setMsgNombre("");
      setNewCliente({ ...newCliente, nombre: name });
    } else {
      setErrorNombre(true);
      setMsgNombre("Ingrese sólo letras");
    }

    if (
      newCliente.nombre.length > 2 &&
      newCliente.apellido.length > 2 &&
      validator.isEmail(newCliente.email)
    ) {
      setNextPaso(true);
    } else {
      setNextPaso(false);
    }
  }

  function apellidoCliente(name) {
    if (validator.isAlpha(name)) {
      setErrorApellido(false);
      setMsgApellido("");
      setNewCliente({ ...newCliente, apellido: name });
    } else {
      setErrorApellido(true);
      setMsgApellido("Ingrese sólo letras");
    }

    if (
      newCliente.nombre.length > 2 &&
      newCliente.apellido.length > 2 &&
      validator.isEmail(newCliente.email)
    ) {
      setNextPaso(true);
    } else {
      setNextPaso(false);
    }
  }

  function getSteps() {
    return [
      "Datos Personales",
      "Registra el nombre de tu tienda",
      "Copia la plantilla de datos",
      "Crea tu tienda",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Grid container className={classes.root}>
            <Grid item md={12}>
              <Typography>
                Iniciamos el registro de tu tienda, completa los siguientes
                campos:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                error={errorNombre}
                id="nombre"
                type="nombre"
                label="Nombre"
                variant="outlined"
                color="primary"
                size="small"
                style={{ width: "95%" }}
                className={classes.margin}
                helperText={msgNombre}
                fullWidth
                autoComplete="on"
                value={newCliente.nombre}
                onChange={(e) => nombreCliente(e.target.value)}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                error={errorApellido}
                id="apellido"
                type="apellido"
                label="Apellido"
                variant="outlined"
                color="primary"
                size="small"
                style={{ width: "95%" }}
                className={classes.margin}
                helperText={msgApellido}
                fullWidth
                autoComplete="on"
                value={newCliente.apellido}
                onChange={(e) => apellidoCliente(e.target.value)}
              />
            </Grid>
            <Grid item md={8}>
              <TextField
                error={errorEmail}
                id="email"
                type="email"
                label="Email"
                variant="outlined"
                color="primary"
                size="small"
                style={{ width: "98%" }}
                className={classes.margin}
                fullWidth
                autoComplete="on"
                value={newCliente.email}
                onChange={(e) =>
                  datosCliente({ ...newCliente, email: e.target.value })
                }
              />
            </Grid>
            <Grid item md={8}>
              <InputLabel
                id=""
                size="small"
                style={{ width: "98%" }}
                className={classes.margin}
                fullWidth
              >
                {" "}
                ¿Como te enteraste de nosotros?
              </InputLabel>
              <Select
                labelId=""
                id="demo-simple-select"
                label="¿Como te enteraste de nosotros?"
                style={{ width: "98%" }}
                label="Como nos conocees"
                variant="outlined"
                className={classes.margin}
                fullWidth
                size="small"
                color="primary"
                onChange={(e) =>
                  datosCliente({ ...newCliente, como: e.target.value })
                }
              >
                {<MenuItem value={"Publicidad"}>{"Publicidad"}</MenuItem>}
                {<MenuItem value={"Recomendacion"}>{"Recomendación"}</MenuItem>}
                {<MenuItem value={"Instagram"}>{"Instagram"}</MenuItem>}
                {<MenuItem value={"Facebook"}>{"Facebook"}</MenuItem>}
                {<MenuItem value={"Google"}>{"Google"}</MenuItem>}
                {<MenuItem value={"Otro"}>{"Otro"}</MenuItem>}
              </Select>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container className={classes.root}>
            <Grid item md={12}>
              <Typography>
                Escribe un nombre para tu tienda, haz clic en ¿Está disponible?
                para comprobar disponibilidad, la usarás como parte del URL que
                te crearemos.
              </Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                error={errorNameURL}
                id="nameURL"
                type="nameURL"
                label="nombre de tu tienda"
                variant="outlined"
                color="primary"
                size="small"
                className={classes.margin}
                helperText={msgNameURL}
                fullWidth
                value={newCliente.nameURL}
                onChange={(e) => valNameURL(e.target.value)}
              />
            </Grid>
            <Grid item md={4}>
              {!validateURL || loading ? (
                <HelpIcon className={classes.margin} />
              ) : disponible ? (
                <Tooltip title="Está disponible">
                  <CheckCircleIcon className={classes.margin} color="primary" />
                </Tooltip>
              ) : (
                disponible === false && (
                  <Tooltip title="El nombre no esta disponible">
                    <ErrorIcon className={classes.margin} color="secondary" />
                  </Tooltip>
                )
              )}

              <Button
                disabled={botonDisponible}
                className={classes.margin}
                variant="contained"
                color="primary"
                onClick={(e) => esDisponible()}
              >
                {loading && typeLoading === "disponibilidad" ? (
                  <Typography color="initial">
                    <CircularProgress
                      size="1rem"
                      style={{ color: "#efefef" }}
                    />{" "}
                    Validando
                  </Typography>
                ) : (
                  "¿Está disponible?"
                )}
              </Button>
              {errorCliente && (
                <Typography variant="body1" color="initial">
                  {msjErrorCliente}
                </Typography>
              )}
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Typography>
            <Link
              href="https://docs.google.com/spreadsheets/d/1yCmZ3g5NXjBmfWWlCbFJxlePks90amMTeaG0bi8VHvk/copy"
              target="_blank"
              onClick={(e) => preventDefault}
            >
              Haz Click aquí para copiar la plantilla de tienda
            </Link>
            , preferiblemente desde una computadora. En el archivo copiado
            registraras luego los datos de tus productos.
            <hr></hr>
            <Button
              variant="contained"
              size="small"
              onClick={(e) =>
                handleOpen(
                  "https://whatspidelo.com/wp-content/uploads/2020/08/Copia-el-Archivo.png",
                  "phone"
                )
              }
              style={{
                textTransform: "initial",
                backgroundColor: "#58b03f",
                color: "#ffffff",
              }}
              startIcon={<PhoneIphoneIcon />}
            >
              ¿Estas desde el Celular?, haz clic aqui para ver la guía rápida.
            </Button>
          </Typography>
        );
      case 3:
        return (
          <Grid container className={classes.root}>
            <Typography>
              {/* En la plantilla copiada, haz click en el boton de <strong>compartir</strong>, 
                  en la ventana emergente que se genera úbica en la parte inferior la sección de <strong>obtener enlace/vinculo</strong>, 
                  haz click en{" "} <strong>cambiar a cualquier persona con el enlace/vínculo</strong>{" "}
                  luego haz click en <strong>Copiar enlace/vínculo</strong> y finalmente click en <strong>Listo</strong>. 
                   */}
              Ya estás por finalizar el proceso, ahora debes compartir el
              archivo copiado, sigue los pasos según el dispositivo donde estas
              realizando el proceso.
              <hr></hr>
              <Button
                variant="contained"
                size="small"
                onClick={(e) =>
                  handleOpen(
                    "https://whatspidelo.com/wp-content/uploads/2020/08/Pregunta01.png",
                    "pc"
                  )
                }
                style={{
                  textTransform: "initial",
                  backgroundColor: "#58b03f",
                  color: "#ffffff",
                }}
                startIcon={<ComputerIcon />}
              >
                ¿Estas desde el pc?, haz clic aqui para ver la guía rápida.
              </Button>
              <hr></hr>
              <Button
                variant="contained"
                size="small"
                onClick={(e) =>
                  handleOpen(
                    "https://whatspidelo.com/wp-content/uploads/2020/08/Genera-Enlace.png",
                    "phone"
                  )
                }
                style={{
                  textTransform: "initial",
                  backgroundColor: "#58b03f",
                  color: "#ffffff",
                }}
                startIcon={<PhoneIphoneIcon />}
              >
                ¿Estas desde el celular?, haz clic aqui para ver la guía rápida.
              </Button>
              <hr></hr>
              <Link
                href="https://whatspidelo.com/index.php/preguntas-frecuentes/"
                target="_blank"
                onClick={(e) => preventDefault}
              >
                ¿Tienes dudas sobre este paso?, haz clic aquí para ingresar a la
                ayuda.
              </Link>
            </Typography>
            <Grid item md={12}>
              <Typography>
                El enlace copiado debes pegarlo en el siguiente campo, luego haz
                clic en el boton de registro.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errorURL}
                className={classes.margin}
                label="Pega aquí el link copiado"
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                value={newCliente.sheetId}
                onChange={(e) =>
                  datosCliente({
                    ...newCliente,
                    sheetId: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item md={4}>
              <InputLabel
                id=""
                size="small"
                style={{ width: "98%", color: "#000000" }}
                className={classes.margin}
                fullWidth
              >
                {" "}
                ¿Necesitas ayuda para crear tu tienda?
              </InputLabel>
            </Grid>
            <Grid item md={4}>
              {loading ? (
                <HelpIcon className={classes.margin} />
              ) : contactado ? (
                <Tooltip title="Contactado">
                  <CheckCircleIcon className={classes.margin} color="primary" />
                </Tooltip>
              ) : (
                contactado === false && (
                  <Tooltip title="No contactado">
                    <EmojiPeopleIcon
                      className={classes.margin}
                      style={{
                        textTransform: "initial",
                        color: "#ffffff",
                        background: "#F76637",
                      }}
                    />
                  </Tooltip>
                )
              )}

              <Button
                disabled={contactado}
                className={classes.margin}
                variant="contained"
                color="secondaty"
                size="small"
                onClick={(e) => contactar()}
                style={{
                  textTransform: "initial",
                  color: "#efefef",
                  background: "#F76637",
                }}
              >
                {loading && typeLoading === "contactado" ? (
                  <Typography color="initial">
                    <CircularProgress
                      size="1rem"
                      style={{ color: "#efefef" }}
                    />{" "}
                    Validando
                  </Typography>
                ) : (
                  buttonText
                )}
              </Button>
              {errorCliente && (
                <Typography variant="body1" color="initial">
                  {msjErrorCliente}
                </Typography>
              )}
            </Grid>
          </Grid>
        );

      default:
        return "Unknown step";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ paddingLeft: "8" }}>
        <Grid direction="column" alignItems="stretch" container item md={12}>
          <Paper className={classes.paper} elevation={3}>
            {loading && typeLoading === "nuevoCliente" && (
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            )}
            {formulario ? (
              <form noValidate autoComplete="on">
                <Grid>
                  <Grid>
                    <Typography variant="h4" align="center" color="textPrimary">
                      Crea tu tienda Whatspidelo
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    justify="center"
                    style={{ padding: "10px", marginBotton: "15px" }}
                    xs={12}
                  >
                    <Typography align="justify" color="textPrimary">
                      ¡Bienvenido! Estás a pocos pasos de crear tu tienda
                      WhatsPidelo. Es importante prestar atención al Vídeo
                      Tutorial según el dispositivo donde realizaras la creación
                      de la tienda.
                    </Typography>
                    <Typography>
                      <hr></hr>
                      Si estas desde el celular debes tener instalado el App{" "}
                      <Link
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.docs.editors.sheets"
                        target="_blank"
                        onClick={(e) => preventDefault}
                      >
                        Hoja de cálculo de Google, puedes instalarlo haciendo
                        clic aquí.
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item md={6} justify="center">
                      <Button
                        style={{
                          textTransform: "initial",
                          backgroundColor: "#58b03f",
                          color: "#ffffff",
                        }}
                        href="https://www.youtube.com/watch?v=mQ5B707svvI"
                        target="_blank"
                        onClick={(e) => preventDefault}
                      >
                        <PhoneIphoneIcon style={{ marginRight: "10px" }} />
                        Video tutorial (Creación desde Celular)
                      </Button>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      justify="center"
                      style={{ marginBotton: "10px" }}
                    >
                      <Button
                        aria-label=""
                        href="https://www.youtube.com/watch?v=38vsAmlebHo"
                        target="_blank"
                        onClick={(e) => preventDefault}
                        style={{
                          textTransform: "initial",
                          backgroundColor: "#58b03f",
                          color: "#ffffff",
                        }}
                      >
                        <ComputerIcon style={{ marginRight: "10px" }} />
                        Video tutorial (Creación desde el PC)
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Stepper
                      style={{ padding: "4px", marginTop: "10px" }}
                      activeStep={activeStep}
                      orientation="vertical"
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                          <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            <div className={classes.actionsContainer}>
                              <div>
                                <Button
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  className={classes.button}
                                >
                                  Retroceder
                                </Button>

                                {activeStep === steps.length - 1 ? (
                                  <Button
                                    disabled={!disponible || !validateURL}
                                    variant="contained"
                                    className={classes.margin}
                                    color="primary"
                                    onClick={(e) => Validacion()}
                                  >
                                    Registrarme y Obtener mi tienda Online
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={!nextPaso}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                  >
                                    Siguiente
                                  </Button>
                                )}
                              </div>
                            </div>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <CardMedia
                    className={
                      imagenModal.type === "phone"
                        ? classes.mediaPhone
                        : classes.mediaMax
                    }
                    image={imagenModal.url}
                  />
                </Modal>
              </form>
            ) : (
              !loading &&
              (!errorCliente ? (
                <Grid item xs={12}>
                  <ClienteDatos
                    urlShort={clienteCreado.nameURL}
                    urlLarge={urlWP + clienteCreado.nameURL}
                  />
                  <Typography variant="h5" color="initial">
                    Recibirás un email con los datos para tu soporte.
                  </Typography>
                </Grid>
              ) : (
                <Grid id="notfound">
                  <Grid className="notfound">
                    <Grid className="notfound-404">
                      <Typography variant="h1" color="initial">
                        UPS!
                      </Typography>
                      <Typography variant="subtitle1" color="initial">
                        Se ha Generado un error
                      </Typography>
                      <Typography
                        variant="h5"
                        color="initial"
                        style={{ backgroundColor: "#efefef" }}
                      >
                        {msjErrorCliente}
                      </Typography>
                      <Typography variant="subtitle1" color="initial">
                        Intenta nuevamente, verifica que hayas seguido todos los
                        pasos.
                      </Typography>
                      <Typography variant="subtitle2" color="initial">
                        ¿Necesitas soporte? Escríbenos a ventas@whatspidelo.com
                      </Typography>
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => setFormulario(true)}
                    >
                      Regresar
                    </Button>
                  </Grid>
                </Grid>
              ))
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
