import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CardMedia from "@material-ui/core/CardMedia";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  SwipeableDrawer,
  Typography,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import NumberFormat from "react-number-format";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootCard: {
    maxWidth: 300,
  },
  media: {
    height: 300,
    width: 300,
    marginTop: "-60px",
  },
  checkControl: {
    padding: "0px",
    margin: "0px",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1",
  },
  extrasTitle: {
    color: "#000000",
    fontWeight: 600,
    marginBottom: "20px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const DrawerExtras = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerClose = () => {
    props.setOpenDrawer(false);
  };

  const [state, setState] = React.useState([]);

  const handleChange = (event) => {
    const arrayAdd = props.extrasLoad.map((item) =>
      item.nombre === event.target.name
        ? {
          key: item.key,
            nombre: item.nombre,
            isChecked: event.target.checked,
            precio: item.precio,
            grupo: item.grupo,
          }
        : item
    );
    props.setExtrasLoad(arrayAdd);
  };

  const agregarAdicionales = (key) => {
    props.addAdicionales(key);
    handleDrawerClose();
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        className={classes.drawer}
        open={props.open}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <IconButton
          style={{
            backgroundColor: "#4caf50",
            color: "#ffff",
            marginLeft: "10px",
            marginTop: "10px",
            width: "50px",
          }}
          onClick={handleDrawerClose}
        >
          {theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <CardMedia
          className={classes.media}
          image={
            props.producto.thumbnail ||
            "https://api.whatspidelo.com/uploads/1611620549-NoEncontrada.jpg"
          }
          title="Producto"
        />
        <div style={{ padding: "16px", height: "50%" }}>
          <Typography style={{ lineHeight: "1.2" }}>
            {props.producto.title} -
            <NumberFormat
              value={Number(props.producto.price)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={" " + props.moneda + ": "}
            />
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{
              fontSize: "0.875rem",
              fontWeight: 300,
              lineHeight: "1.2",
              paddingBottom: "4px",
            }}
          >
            {props.producto.medida}
          </Typography>
          <Divider />
          <Typography style={{ lineHeight: "1.2", marginBottom: "8px" }}>
            Agregar extras al producto
          </Typography>
{/*           <FormControl>
            <FormGroup>
              {props.extrasLoad.map((item) => (
                <div>
                  { item.grupo === props.producto.grupo &&
                    <FormControlLabel
                      className={classes.checkControl}
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.checkControl}
                          checked={item.isChecked}
                          onChange={handleChange}
                          name={item.nombre}
                        />
                      }
                      label={
                        item.nombre + " - " + props.moneda + ": " + item.precio
                      }
                    />
                  }
                </div>
              ))}
            </FormGroup>
          </FormControl>
 */}
          <List>
          {props.extrasLoad.map((item) => {
           if (item.grupo === props.producto.grupo){
            return <ListItem key={item.key}  style={{
              padding: "4px",
              fontSize: 14
            }}>
              { item.grupo === props.producto.grupo &&
                    <FormControlLabel
                      className={classes.checkControl}
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.checkControl}
                          checked={item.isChecked}
                          onChange={handleChange}
                          name={item.nombre}
                        />
                      }
                      label={
                        item.nombre + " - " + props.moneda + ": " + item.precio
                      }
                    />
                  }
            </ListItem>}
          })}
           <ListItem key={9999} disablePadding>
           <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={() => agregarAdicionales(props.producto.key)}
          >
            Guardar
          </Button>
           </ListItem>
        </List>




        </div>
       {/*  <div style={{ display: "grid", padding: "16px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => agregarAdicionales(props.producto.key)}
          >
            Agregar extras
          </Button>
        </div> */}
      </SwipeableDrawer>
    </div>
  );
};

export default DrawerExtras;
