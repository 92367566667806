import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Avatar, Grid, Radio, TextField } from "@material-ui/core";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PaymentIcon from "@material-ui/icons/Payment";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { useCompradorContext } from "../context/CompradorContext";

const useStyles = makeStyles((theme) => ({
  nameFormaPago: {
    marginLeft: "10px",
    marginTop: "7px",
    alignItems: "left",
   textAlign: "initial",
    fontWeight: 500,
    width: "100%",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "10px",
    "& .MuiTextField-root": {
      margin: "4px",
      width: "100%",
    },
  },
  cardFormaPago: {
    borderColor: "#2E294E",
    borderWidth: "2px",
    marginTop: "3px",
    backgroundColor: "#2E294E1",
    borderStyle: "dashed",
    borderRadius: "3px",
  },
}));

const Accordion = withStyles({
  root: {
    //border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    marginTop: "3px",
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fdfdfd",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderColor: "#2E294EC4",
    borderWidth: "2px",
    marginTop: "3px",
    backgroundColor: "#904cae29",
    borderStyle: "dashed",
    borderRadius: "3px",
    textAlign: "center",
    width: "100%",
  },
}))(MuiAccordionDetails);

export default function FormasPago(props) {
  const [selectedValue, setSelectedValue] = React.useState("");
  const { comprador, setComprador } = useCompradorContext();
  const handleChangeOP = (event, param) => {
    setSelectedValue(event.target.value);
    setComprador({
      ...comprador,
      formaPago: {
        cod: param.cod,
        tipo: param.tipo,
        nombreBanco: param.nombreBanco,
        cuenta: param.cuenta,
        titular: param.titular,
        cedula: param.cedula,
      },
    });
  };
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function switchFormasPago(param) {
    switch (param.cod) {
      case "mp1":
        return (
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Radio
                checked={selectedValue === "a"}
                onChange={(e) => handleChangeOP(e, param)}
                value="a"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
                style={{ color: "#F5C738" }}
              />
              <Avatar style={{ backgroundColor: "#207173", color: "#eee" }}>
                <AccountBalanceIcon />
              </Avatar>
              <Typography className={classes.nameFormaPago}>
                Transferencia {param.nombreBanco} 
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{textAlign: "initial"}}>
                <strong>Banco:</strong> {param.nombreBanco} <br />
                <strong>Nro. Cuenta:</strong> {param.cuenta} <br />
                <strong>Titular:</strong> {param.titular} <br />
                <strong>Nro. Doc.:</strong> {param.cedula}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
        case "mp2":
        return (
          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Radio
                checked={selectedValue === "b"}
                onChange={(e) => handleChangeOP(e, param)}
                value="b"
                name="radio-button-demo"
                inputProps={{ "aria-label": "B" }}
                style={{ color: "#F5C738" }}
              />
              <Avatar style={{ backgroundColor: "#029FA2", color: "#eee" }}>
                <AccountBalanceIcon />
              </Avatar>
              <Typography className={classes.nameFormaPago}>
                Transferencia {param.nombreBanco} 
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{textAlign: "initial"}}>
                <strong>Banco:</strong> {param.nombreBanco} <br />
                <strong>Nro. Cuenta:</strong> {param.cuenta} <br />
                <strong>Titular:</strong> {param.titular} <br />
                <strong>Nro. Doc.:</strong> {param.cedula}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
        case "mp3":
          return (
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Radio
                  checked={selectedValue === "c"}
                  onChange={(e) => handleChangeOP(e, param)}
                  value="c"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "C" }}
                  style={{ color: "#F5C738" }}
                />
                <Avatar style={{ backgroundColor: "#3DD6D9", color: "#eee" }}>
                  <AccountBalanceIcon />
                </Avatar>
                <Typography className={classes.nameFormaPago}>
                  Transferencia {param.nombreBanco} 
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{textAlign: "initial"}}>
                  <strong>Banco:</strong> {param.nombreBanco} <br />
                  <strong>Nro. Cuenta:</strong> {param.cuenta} <br />
                  <strong>Titular:</strong> {param.titular} <br />
                  <strong>Nro. Doc.:</strong> {param.cedula}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
      case "mp4":
        return (
          <Accordion
            square
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Radio
                checked={selectedValue === "d"}
                onChange={(e) => handleChangeOP(e, param)}
                value="d"
                name="radio-button-demo"
                inputProps={{ "aria-label": "D" }}
                style={{ color: "#F5C738" }}
              />
              <Avatar style={{ backgroundColor: "#9BD72C", color: "#eee" }}>
                <LocalAtmIcon />
              </Avatar>
              <Typography className={classes.nameFormaPago}>
                Efectivo
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id=""
                label="Cantidad en efectivo"
                helperText="Ciertas condiciones aplican."
              />
            </AccordionDetails>
          </Accordion>
        );
      case "mp5":
        return (
          <Accordion
            square
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Radio
                checked={selectedValue === "e"}
                onChange={(e) => handleChangeOP(e, param)}
                value="e"
                name="radio-button-demo"
                inputProps={{ "aria-label": "E" }}
                style={{ color: "#F5C738" }}
              />
              <Avatar style={{ backgroundColor: "#d92c4c", color: "#ffffff" }}>
                <FlashOnIcon />
              </Avatar>
              <Typography className={classes.nameFormaPago}>
                Pago Móvil
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{textAlign: "initial"}}>
                <strong>Banco:</strong> {param.nombreBanco} <br />
                <strong>Nro. Telf.:</strong> {param.cuenta} <br />
                <strong>Nro. Doc.:</strong> {param.cedula}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      case "mp6":
        return (
          <Accordion
            square
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Radio
                checked={selectedValue === "f"}
                onChange={(e) => handleChangeOP(e, param)}
                value="f"
                name="radio-button-demo"
                inputProps={{ "aria-label": "F" }}
                style={{ color: "#F5C738" }}
              />
              <Avatar
                alt="Remy Sharp"
                src="https://api.whatspidelo.com/uploads/1608174909-zelle.png"
              />
              <Typography className={classes.nameFormaPago}>Zelle</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{textAlign: "initial"}}>
                <strong>Banco:</strong> {param.nombreBanco} <br />
                <strong>email:</strong> {param.cuenta} <br />
                <strong>Titular:</strong> {param.titular}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      case "mp7":
        return (
          <Accordion
            square
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Radio
                checked={selectedValue === "g"}
                onChange={(e) => handleChangeOP(e, param)}
                value="g"
                name="radio-button-demo"
                inputProps={{ "aria-label": "G" }}
                style={{ color: "#F5C738" }}
              />
              <Avatar
                alt="Remy Sharp"
                src="https://api.whatspidelo.com/uploads/1608175615-PayPal-Logo-650x366.jpg"
              />
              <Typography className={classes.nameFormaPago}>PayPal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{textAlign: "initial"}}>
                <strong>Email:</strong> {param.cuenta} <br />
                <strong>Titular:</strong> {param.titular} <br />
                Recuerde incluir el monto de comisión que cobra PayPal.
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      default:
        return;
    }
  }
 
  return props.formasDePago.length ? (
    <Grid container className={classes.root}>
      <Grid item style={{ padding: "4px", textAlign: "center", width: "100%" }}>
        <Typography variant="h6" component="h2" style={{ textAlign: "left" }}>
       Tipo de pago
        </Typography>
        
        {props.formasDePago.map((item) => (
          <div>{switchFormasPago(item)}</div>
        ))}
      </Grid>
    </Grid>
  ) : null;
}
