import { GoogleSpreadsheet } from "google-spreadsheet";
import groupArray from "group-array";

//Constantes
const imageNotFound = "imageNotFound.jpg";
const prod = {
  key: "",
  title: "",
  price: "",
  thumbnail: "",
  medida: "",
  tipo: "",
};
const dataInicial = {
  loading: true,
  count: 0,
  next: null,
  previous: null,
  results: [],
  product: { prod },
};

//types

const LOADING = "LOADING";
const OBTENER_PRODUCTOS_EXITO = "OBTENER_PRODUCTOS_EXITO";
const PRODUCTO_ERROR = "PRODUCTO_ERROR";

const API_KEY = "AIzaSyByRA6Lq3iE4g1QukMC-aH8lYqYhsbG3sw";

const creds = require("../creds/quickstart-1592014430776-7a89ad3bdfe0.json"); // Credenciales de acceso readOnly

//Reducers

export default function productosReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case OBTENER_PRODUCTOS_EXITO:
      return { action, loading: false, ...action.payload };
    case PRODUCTO_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

//Acciones

export const obtenerProductosAccion = (sheet) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    /*  ---- AJUSTES POR CAMBIO DE CONEXION ---- */
    /* const doc = new GoogleSpreadsheet(key);
    await doc.useServiceAccountAuth(creds);
    doc.useApiKey(API_KEY);
    await doc.loadInfo();
    const sheet = doc.sheetsByIndex[0]; */
    /* 
    const rows = await sheet.getRows();
    console.log("en get rows"); */
    /* const results = rows.filter(
      (item) =>
        item._rowNumber > 9 &&
        (item._rawData[5].toLowerCase() === "si" ||
          item._rawData[5].toLowerCase() === "sí" ||
          item._rawData[5].toLowerCase() === "agotado")
    ); */
    var productos = [];
    var i = 1;
    while (i < sheet.rowCount - 1 && sheet.getCell(i, 0).value !== null) {
      if (sheet.getCell(i, 0).value !== null) {
        if (
          sheet.getCell(i, 5).value.toLowerCase() === "si" ||
          sheet.getCell(i, 5).value.toLowerCase() === "sí" ||
          sheet.getCell(i, 5).value.toLowerCase() === "agotado"
        ) {
          //let imageURL = await checkImage(sheet.getCell(i, 3).value);
          productos = [
            ...productos,
            {
              category: sheet.getCell(i, 0).value,
              key: i,
              title: sheet.getCell(i, 1).value,
              price: sheet.getCell(i, 4).value,
              thumbnail: sheet.getCell(i, 3).value /* imageURL */,
              medida: sheet.getCell(i, 2).value,
              activo:
                sheet.getCell(i, 5).value !== null
                  ? sheet.getCell(i, 5).value.toLowerCase()
                  : null,
              grupo:
                sheet.getCell(i, 6).value !== null
                  ? sheet.getCell(i, 6).value.toLowerCase()
                  : null,
              filter: true,
              tipo: sheet.getCell(i, 7).value,
              unidadTipo: 2,
              factor: 1,
              listaPersonalizada: sheet.getCell(i, 8).value !== null && sheet.getCell(i, 7).value !== 'Peso' ? sheet.getCell(i, 8).value.split('|') : (
                sheet.getCell(i, 7).value === 'Peso' ? [
                  { value: 0, label: '250 grs' },
                  { value: 1, label: '500 grs' },
                  { value: 2, label: '1 Kg' }
                ] 
                :
                null
              ),
            },
          ];
        }
      }

      i = i + 1;
    }

    //Llenado de productos en objeto
    /* results.map(
      (item) =>
        (productos = [
          ...productos,
          {
            category: item._rawData[0],
            key: item._rowNumber,
            title: item._rawData[1],
            price: item._rawData[4],
            thumbnail: item._rawData[3],
            medida: item._rawData[2],
            activo:
              item._rawData[5] !== null ? item._rawData[5].toLowerCase() : null,
            grupo:
              item._rawData[6] !== null ? item._rawData[6].toLowerCase() : null,
            filter: true,
          },
        ])
    );
 */
    //Crea una agrupación por el campo categoria
    var listaProductos = groupArray(productos, "category");

    const data = { listaProductos };
    dispatch({
      type: OBTENER_PRODUCTOS_EXITO,
      payload: data,
    });
  } catch (error) {
    console.log("ErrorApi: " + error);
    dispatch({
      type: PRODUCTO_ERROR,
    });
  }
};

export const buscarProductosAccion =
  (productos, term) => async (dispatch, getState) => {
    try {
      //const results = rows.filter (item =>  (item._rowNumber > 9  && item._rawData[5] === 'Si' &&  item._rawData[1].toLo) )
      var productosFiltrado = [];
      for (var key in productos) {
        for (var i = 0; i < productos[key].length; i++) {
          if (
            productos[key][i].title.toLowerCase().indexOf(term.toLowerCase()) >
            -1
          ) {
            productosFiltrado = [
              ...productosFiltrado,
              {
                category: productos[key][i].category,
                key: productos[key][i].key,
                title: productos[key][i].title,
                price: productos[key][i].price,
                thumbnail: productos[key][i].thumbnail,
                medida: productos[key][i].medida,
                activo: productos[key][i].activo,
                grupo: productos[key][i].grupo,
                filter: true,
                tipo: productos[key][i].tipo,
                unidadTipo: productos[key][i].unidadTipo,
                factor: productos[key][i].factor,
                listaPersonalizada: productos[key][i].listaPersonalizada,
              },
            ];
          } else {
            productosFiltrado = [
              ...productosFiltrado,
              {
                category: productos[key][i].category,
                key: productos[key][i].key,
                title: productos[key][i].title,
                price: productos[key][i].price,
                thumbnail: productos[key][i].thumbnail,
                medida: productos[key][i].medida,
                activo: productos[key][i].activo,
                grupo: productos[key][i].grupo,
                filter: false,
                tipo: productos[key][i].tipo,
                unidadTipo: productos[key][i].unidadTipo,
                factor: productos[key][i].factor,
                listaPersonalizada: productos[key][i].listaPersonalizada,
              },
            ];
          }
        }
      }

      //Crea una agrupación por el campo categoria
      var listaProductos = groupArray(productosFiltrado, "category");

      const data = { listaProductos };
      dispatch({
        type: OBTENER_PRODUCTOS_EXITO,
        payload: data,
      });
    } catch (error) {
      console.log("ErrorApi: " + error);
      dispatch({
        type: PRODUCTO_ERROR,
      });
    }
  };

  export const actualizarPrecioProductosAccion =
  (productos, id, factor) => async (dispatch, getState) => {
    try {
      //const results = rows.filter (item =>  (item._rowNumber > 9  && item._rawData[5] === 'Si' &&  item._rawData[1].toLo) )
      var productosFiltrado = [];
      for (var key in productos) {
        for (var i = 0; i < productos[key].length; i++) {
          if (
            productos[key][i].key === id
          ) {
            productosFiltrado = [
              ...productosFiltrado,
              {
                category: productos[key][i].category,
                key: productos[key][i].key,
                title: productos[key][i].title,
                price: productos[key][i].price,
                thumbnail: productos[key][i].thumbnail,
                medida: productos[key][i].medida,
                activo: productos[key][i].activo,
                grupo: productos[key][i].grupo,
                filter: productos[key][i].filter,
                tipo: productos[key][i].tipo,
                unidadTipo: productos[key][i].unidadTipo,
                factor: factor,
                listaPersonalizada: productos[key][i].listaPersonalizada,
              },
            ];
          } else {
            productosFiltrado = [
              ...productosFiltrado,
              {
                category: productos[key][i].category,
                key: productos[key][i].key,
                title: productos[key][i].title,
                price: productos[key][i].price,
                thumbnail: productos[key][i].thumbnail,
                medida: productos[key][i].medida,
                activo: productos[key][i].activo,
                grupo: productos[key][i].grupo,
                filter: productos[key][i].filter,
                tipo: productos[key][i].tipo,
                unidadTipo: productos[key][i].unidadTipo,
                factor: productos[key][i].factor,
                listaPersonalizada: productos[key][i].listaPersonalizada,
              },
            ];
          }
        }
      }

      //Crea una agrupación por el campo categoria
      var listaProductos = groupArray(productosFiltrado, "category");

      const data = { listaProductos };
      dispatch({
        type: OBTENER_PRODUCTOS_EXITO,
        payload: data,
      });
    } catch (error) {
      console.log("ErrorApi: " + error);
      dispatch({
        type: PRODUCTO_ERROR,
      });
    }
  };


const checkImage = (url) =>
  new Promise((resolve) => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = () => resolve(imageNotFound);
  });
