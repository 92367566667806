import { db } from "../firebase";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { auth } from "../firebase";
import sendMail from "./Mail";
import moment from "moment";

//Constantes
const dataInicial = {
    loading: true,
    error: false,
    msjerror: null,
    count: 0,
    next: null,
    previous: null,
    disponible: false,
    contactado: false,
    cliente: {
        sheetId: null,
        nameURL: null,
        licencia: null,
        telefono: null,
        empresa: null,
        email: null,
        photoURL: null,
        shortUrl: null,
        direccion: null,
        moneda: null,
        created_at: null,
        horario: null,
        horario2: null,
        correo_atencion: null
    },
    results: [],
    sheet: null,
    settings: null
};

//types

const LOADING = "LOADING";
const CLIENTE_EXITO = "CLIENTE_EXITO";
const CLIENTE_ERROR = "CLIENTE_ERROR";
const LISTA_CLIENTES_EXITO = "LISTA_CLIENTES_EXITO";
const NOMBRE_CLIENTE_DISPONIBLE = "NOMBRE_CLIENTE_DISPONIBLE"; //nombre de la tienda disponible
const CLIENTE_CONTACTADO = "CLIENTE_CONTACTADO"; //prospecto contactado

//Credencialey permsisos de acceso para api google sheets
//AIzaSyByRA6Lq3iE4g1QukMC-aH8lYqYhsbG3sw
const API_KEY = "AIzaSyByRA6Lq3iE4g1QukMC-aH8lYqYhsbG3sw"; // Api key generada por google en la cuenta de danny.oberto@gmail.com

const creds = require("../creds/quickstart-1592014430776-7a89ad3bdfe0.json"); // Credenciales de acceso readOnly

//Reducers

export default function clienteReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: true,
                error: false,
                typeLoading: action.payload,
            };
        case CLIENTE_EXITO:
            return {
                ...state,
                loading: false,
                cliente: action.payload /* , results: [...action.payload] */ ,
                sheet: action.sheet,
                settings: action.settings
            };
        case LISTA_CLIENTES_EXITO:
            return {...state, loading: false, results: [...action.payload] };
        case NOMBRE_CLIENTE_DISPONIBLE:
            return {...state, loading: false, disponible: action.payload };
        case CLIENTE_CONTACTADO:
            return {...state, loading: false, contactado: action.payload };
        case CLIENTE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                msjerror: action.payload,
            };
        default:
            return state;
    }
}

//Acciones

//retorna todos los clientes creados
export const obtenerClientesAccion = () => async(dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: "obtener",
    });
    try {
        const data = await db.collection("clientes").get();
        const results = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        dispatch({
            type: LISTA_CLIENTES_EXITO,
            payload: results,
        });
    } catch (error) {
        console.log(error);
    }
};

//Obtiene el cliente por el nameURL
export const obtenerClienteAccion = (nameURL) => async(dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: "buscarCliente",
    });
    try {
        var Tini = moment();
        var cliente = null;
        var doc = null;
        var sheet = {};
        var settings = {};
        await auth.signInWithEmailAndPassword(
            "admin@whatspidelo.com",
            "Venezuela1."
        );

        if (nameURL === undefined) {
            dispatch({
                type: CLIENTE_ERROR,
                payload: "404 - Verifique el URL de la tienda.",
            });
            return;
        }

        await db
            .collection("clientes")
            .where("nameURL", "==", nameURL.toLowerCase())
            .limit(1)
            .get()
            .then(async(snapshot) => {
                if (snapshot.empty) {
                
                    var Tfin = moment();
                    console.log("Time out: " + Tfin.diff(Tini, "seconds") + " seconds");
                    if (Tfin.diff(Tini, "seconds") >= 10) {
                        dispatch({
                            type: CLIENTE_ERROR,
                            payload: "En este momento su conexión de internet es inestable, por favor intenta más tarde",
                        });
                    } else {
                        dispatch({
                            type: CLIENTE_ERROR,
                            payload: "La tienda no se encontró",
                        });
                    }
                    return;
                } else {
                    cliente = snapshot.docs[0].data();
                    doc = new GoogleSpreadsheet(cliente.sheetId);
                    console.log('entrando leer Google Api');
                    await doc.useServiceAccountAuth(creds);
                    doc.useApiKey(API_KEY);
                    await doc.loadInfo();

                    dispatch({
                        type: CLIENTE_EXITO,
                        payload: cliente,
                        sheet: null,
                        settings: null
                    });

                    //Si el cliente existe se verifica que los datos no hallan cambiado
                    if (cliente != null) {
                        /*  ---- AJUSTES POR CAMBIO DE CONEXION ---- */
                        /* const doc = new GoogleSpreadsheet(cliente.sheetId);
                        await doc.useServiceAccountAuth(creds);
                        doc.useApiKey(API_KEY);
                        await doc.loadInfo(); */
                        sheet = doc.sheetsByTitle["Productos"];
                        settings = doc.sheetsByTitle["Configuracion"];
                        await sheet.loadCells();
                        await settings.loadCells();
                        /* 
                                    const sheetDatosPedido = doc.sheetsByIndex[0];
                                    await sheetDatosPedido.loadCells("P11:p16"); */
                        //Objeto del Excel
                        let imageURL = await checkImage(settings.getCell(0, 4).value);
                        const clienteSheet = {
                            sheetId: cliente.sheetId,
                            licencia: settings.getCell(1, 1).value,
                            telefono: settings.getCell(3, 1).value,
                            empresa: settings.getCell(0, 1).value,
                            photoURL: imageURL,
                            horario: settings.getCell(1, 4).value,
                            horario2: settings.getCell(2, 4).value,
                            direccion: settings.getCell(3, 4).value,
                            moneda: settings.getCell(2, 1).value,
                            instagram: settings.getCell(4, 1).value,
                            tasa: settings.getCell(5, 4).value,
                            costo_delivery: 0,
                            email: cliente.email,
                            nro_documento: settings.getCell(19, 5).value,
                            numero: settings.getCell(20, 5).value,
                            cuenta_ig: settings.getCell(21, 5).value,
                            comentarios: settings.getCell(22, 5).value,
                            sector: settings.getCell(23, 5).value,
                            retiro: settings.getCell(24, 5).value,
                            correo_atencion: settings.getCell(4, 4).value,
                        };

                        //Validacion de datos que no hallan cambiado
                        if (
                            cliente.licencia !== clienteSheet.licencia ||
                            cliente.telefono !== clienteSheet.telefono ||
                            cliente.empresa !== clienteSheet.empresa ||
                            cliente.photoURL !== clienteSheet.photoURL ||
                            cliente.direccion !== clienteSheet.direccion ||
                            cliente.horario !== clienteSheet.horario ||
                            cliente.moneda !== clienteSheet.moneda
                        ) {
                            await db.collection("clientes").doc(cliente.sheetId).update({
                                licencia: clienteSheet.licencia,
                                telefono: clienteSheet.telefono,
                                empresa: clienteSheet.empresa,
                                photoURL: clienteSheet.photoURL,
                                direccion: clienteSheet.direccion,
                                horario: clienteSheet.horario,
                                moneda: clienteSheet.moneda,
                            });
                        }
                        dispatch({
                            type: CLIENTE_EXITO,
                            payload: clienteSheet,
                            sheet: sheet,
                            settings: settings
                        });
                    }
                }
            })
            .catch((err) => {
                let emailWhatspidelo = "whatspidelo@gmail.com";
                sendMail(`Error en tienda ${nameURL}`, emailWhatspidelo, err.message, 6);
                if (err.message.includes('invalid_grant')) {
                    err.message = 'Por favor, comprueba la configuración de la hora y la zona horaria de tu teléfono. Es posible que tengas que reajustarlos.'
                }
                dispatch({
                    type: CLIENTE_ERROR,
                    payload: err.message,
                });
            });


    } catch (error) {
        console.log(error.message);
        var errMsj = "";
        if (error.code === "400") {
            errMsj = "Error 400 - Su conexión es inestable. Intente más tarde";
        } else {
            errMsj = "Error " + error + ", Intente más tarde";
            let emailWhatspidelo = "whatspidelo@gmail.com";
            sendMail(`Error en tienda ${nameURL}`, emailWhatspidelo, error.message, 6);
        }

        dispatch({
            type: CLIENTE_ERROR,
            payload: errMsj,
        });
    }
};

export const nuevoClienteAccion = (nuevoCliente) => async(
    dispatch,
    getState
) => {
    dispatch({
        type: LOADING,
        payload: "nuevoCliente",
    });
    try {
        const doc = new GoogleSpreadsheet(nuevoCliente.sheetId);
        await doc.useServiceAccountAuth(creds);
        doc.useApiKey(API_KEY);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        await sheet.loadCells("A1:E4");

        const urlshort =
            "https://shop.whatspidelo.com/" + nuevoCliente.nameURL.toLowerCase();

        const cliente = {
            nombre: nuevoCliente.nombre,
            apellido: nuevoCliente.apellido,
            sheetId: nuevoCliente.sheetId,
            nameURL: nuevoCliente.nameURL.toLowerCase(),
            licencia: sheet.getCell(1, 1).value,
            telefono: sheet.getCell(3, 1).value,
            empresa: sheet.getCell(0, 1).value,
            email: nuevoCliente.email.toLowerCase(),
            photoURL: sheet.getCell(0, 4).value,
            horario: sheet.getCell(1, 4).value,
            shortUrl: urlshort,
            direccion: sheet.getCell(2, 4).value,
            moneda: sheet.getCell(2, 1).value,
            vendedor_id: nuevoCliente.vendedor_id,
            created_at: Date.now(),
        };

        const clienteDB = await db
            .collection("clientes")
            .doc(cliente.sheetId)
            .get();

        if (clienteDB.exists) {
            dispatch({
                type: CLIENTE_EXITO,
                payload: clienteDB.data(),
            });
            //localStorage.setItem('usuario', JSON.stringify(usuario))
        } else {
            // El CLIENTE no existe en firestore

            await db.collection("clientes").doc(cliente.sheetId).set(cliente);

            let message = {
                nameUrl: cliente.nameURL,
                sheetGoogle: cliente.sheetId,
            };
            //Envio mail de bienvenida Tipo 3

            const vendedorDB = await db
                .collection("vendedores")
                .doc(nuevoCliente.vendedor_id)
                .get();
            let vendedor = vendedorDB.data();

            //Se elimina de la colección de Prospectos ya que se terminó de registrar
            var prospecto = await db
                .collection("prospectos")
                .where("email", "==", cliente.email);
            prospecto.get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    doc.ref.delete();
                });
            });

            const nombre = cliente.nombre + " " + cliente.apellido;

            sendMail(nombre, cliente.email, message, 3);
            sendMail(nombre, vendedor.email, message, 4);

            dispatch({
                type: cliente.sheetId,
                payload: cliente,
            });
        }

        dispatch({
            type: CLIENTE_EXITO,
            payload: cliente,
        });
    } catch (error) {
        console.log(error);

        if (
            error.message ===
            "Google API error - [403] The caller does not have permission"
        ) {
            error.message =
                "No pudimos acceder al archivo de tu tienda, verificar que se haya compartido correctamente.";
        }
        dispatch({
            type: CLIENTE_ERROR,
            payload: error.message,
        });
    }
};

//validar disponibilidad nombre de tienda
export const disponibilidadNombreClienteAccion = (
    nameURL,
    nombreP,
    apellidoP,
    emailP,
    //comoP
) => async(dispatch, getState) => {
    dispatch({
        type: LOADING,
        payload: "disponibilidad",
    });
    try {
        var Tini = moment();
        await db
            .collection("clientes")
            .where("nameURL", "==", nameURL.toLowerCase())
            .limit(1)
            .get()
            .then((snapshot) => {
                if (snapshot.empty) {
                    var Tfin = moment();

                    if (Tfin.diff(Tini, "seconds") >= 10) {
                        dispatch({
                            type: CLIENTE_ERROR,
                            payload: "En este momento su conexión de internet es inestable, por favor intenta más tarde.",
                        });
                    } else {
                        dispatch({
                            type: NOMBRE_CLIENTE_DISPONIBLE,
                            payload: true,
                        });
                    }
                    return;
                } else {
                    dispatch({
                        type: NOMBRE_CLIENTE_DISPONIBLE,
                        payload: false,
                    });
                    return;
                }
            })
            .catch((err) => {
                console.log("Error getting documents", err);
                dispatch({
                    type: CLIENTE_ERROR,
                });
            });

        const prospecto = await db
            .collection("prospectos")
            .where("email", "==", emailP)
            .get();

        if (!prospecto) {
            await db
                .collection("prospectos")
                .doc()
                .set({
                    apellido: apellidoP,
                    nombre: nombreP,
                    email: emailP,
                    //como: comoP,
                    date: moment().format("LLL"),
                });
        }
    } catch (error) {
        console.log(error);
        dispatch({
            type: CLIENTE_ERROR,
            payload: error.code,
        });
    }
};

//validar disponibilidad nombre del URL de tienda para nuevo registro
export const disponibilidadNombreURLAccion = (nameURL) => async(
    dispatch,
    getState
) => {
    dispatch({
        type: LOADING,
        payload: "disponibilidad",
    });
    try {
        var Tini = moment();
        await db
            .collection("clientes")
            .where("nameURL", "==", nameURL.toLowerCase())
            .limit(1)
            .get()
            .then((snapshot) => {
                if (snapshot.empty) {
                    var Tfin = moment();
                    console.log("Time out: " + Tfin.diff(Tini, "seconds") + " seconds");

                    if (Tfin.diff(Tini, "seconds") >= 10) {
                        dispatch({
                            type: CLIENTE_ERROR,
                            payload: "En este momento su conexión de internet es inestable, por favor intenta más tarde.",
                        });
                    } else {
                        dispatch({
                            type: NOMBRE_CLIENTE_DISPONIBLE,
                            payload: true,
                        });
                    }
                    return;
                } else {
                    dispatch({
                        type: NOMBRE_CLIENTE_DISPONIBLE,
                        payload: false,
                    });
                    return;
                }
            })
            .catch((err) => {
                console.log("Error getting documents", err);
                dispatch({
                    type: CLIENTE_ERROR,
                });
            });
    } catch (error) {
        console.log(error);
        dispatch({
            type: CLIENTE_ERROR,
            payload: error.code,
        });
    }
};

//contactar a un Prospecto por email
export const contactarProspecto = (emailP, nombreP, apellidoP) => async(
    dispatch,
    getState
) => {
    dispatch({
        type: LOADING,
        payload: "contactado",
    });
    try {
        //Se elimina de la colección de Prospectos ya que se terminó de registrar
        var prospecto = await db
            .collection("prospectos")
            .where("email", "==", emailP);
        prospecto.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                doc.ref.update({
                    contactar: "si",
                });
            });
        });
        let nombre = nombreP + " " + apellidoP;

        let message = emailP;
        let emailWhatspidelo = "ventas@whatspidelo.com";

        sendMail(nombre, emailWhatspidelo, message, 6);
        dispatch({
            type: CLIENTE_CONTACTADO,
            payload: true,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: CLIENTE_ERROR,
            payload: error.code,
        });
    }
};

//Registrr prospecto
export const registrarProspectoAccion = (clienteNew) => async(
    dispatch,
    getState
) => {
    dispatch({
        type: LOADING,
        payload: "disponibilidad",
    });
    try {
        await db.collection("prospectos").doc().set({
            nombre: clienteNew.nombre,
            apellido: clienteNew.apellido,
            email: clienteNew.email,
            //como: clienteNew.como,
            nameURL: clienteNew.nameURL,
            telefono: clienteNew.telefono,
            vendedor_id: clienteNew.vendedor_id,
            created_at: Date.now(),
        });

        let message = {
            nameUrl: clienteNew.nameURL,
        };
        let nombres = clienteNew.nombre + " " + clienteNew.apellido;

        sendMail(nombres, clienteNew.email, message, 7);

        let datos = {
            nameUrl: clienteNew.nameURL,
            email: clienteNew.email,
            //como: clienteNew.como,
            telefono: clienteNew.telefono,
            vendedor_id: clienteNew.vendedor_id,
        };

        await db
            .collection("vendedores")
            .where("cod_vendor", "==", clienteNew.vendedor_id)
            .limit(1)
            .get()
            .then((snapshot) => {
                if (snapshot.empty) {
                    console.log('vacio');
                } else {
                    let vendedor = snapshot.docs[0].data();
                    sendMail(nombres, vendedor.email, datos, 8);
                }
            })
            .catch((err) => {
                console.log("Error getting documents", err.message);
            });

        dispatch({
            type: CLIENTE_EXITO,
            payload: clienteNew,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: CLIENTE_ERROR,
            payload: error.code,
        });
    }
};
const checkImage = (url) =>
  new Promise((resolve) => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = () => resolve(null);
  });