import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  LinearProgress,
  Button
} from "@material-ui/core";
import { obtenerProspectosAccion } from "../redux/Prospectos";
import { useSelector, useDispatch } from "react-redux";
import sendMail from "../redux/Mail";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 50,
  },
  formulario: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
}));

const Prospectos = () => {
  const dispatch = useDispatch();
  const prospectos = useSelector((store) => store.prospectos.results);
  const loading = useSelector((store) => store.prospectos.loading);
  const typeLoading = useSelector((store) => store.prospectos.typeLoading);

  React.useEffect(() => {
    const obtenerDatos = async () => {
      try {
        dispatch(obtenerProspectosAccion());
      } catch (error) {
        console.log(error);
      }
    };

    obtenerDatos();
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container >
        <Grid md={12} >
          <Typography variant="h5" color="initial">
            Prospectos
          </Typography>
          <Divider />
          {loading && typeLoading === "eliminar" && (
            <LinearProgress color="secondary" />
          )}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell>Nombre / Email</StyledTableCell>
                  <StyledTableCell>Telefono</StyledTableCell>
                  <StyledTableCell>Vendedor</StyledTableCell>
                  <StyledTableCell>Tienda</StyledTableCell>
                  <StyledTableCell>Fecha</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prospectos.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.nombre} {item.apellido} / {item.email}</TableCell>
                    <TableCell>{item.telefono}</TableCell>
                    <TableCell>{item.vendedor}</TableCell>
                    <TableCell>{item.tienda}</TableCell>
                    <TableCell>{item.fecha}</TableCell>
                    <TableCell>                      
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            sendMail(
                              item.nombre+' '+item.apellido,
                              item.email,
                              item.tienda,
                              9
                            )
                          }
                        >
                          Enviar
                        </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Prospectos;
