import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  ImageListItem,
  ImageList,
  Button,
  LinearProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperProgress: {
    padding: theme.spacing(2),
    width: "100%",
    textAlign: "center",
    height: "30px",
  },

  grid: {
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    overflow: "hidden",
  },
  glow: {
    color: "#000",
    backgroundColor: "#fff",
    padding: "5px 10px 5px 10px",
    borderRadius: "4px",
    fontWeight: "normal",
    textTransform: "uppercase",
    transition: "all 0.2s ease-in-out,",
  },
}));

export default function ProductosCategorias(props) {
  const classes = useStyles();

  return (
    <Grid style={{ margin: "auto" }}>
      {props.loading || props.productos === undefined ? (
        <Grid container>
          <Paper className={classes.paperProgress}>
            <LinearProgress />
          </Paper>
        </Grid>
      ) : (
        <Grid item direction="column" className={classes.grid} cols={2.5}>
          <ImageList
            cellHeight={40}
            style={{ flexWrap: "nowrap", transform: "translateZ(0)" }}
          >
            {Object.keys(props.productos).map(function (keyName, keyIndex) {
              return (
                <ImageListItem
                  key={keyName}
                  style={{ width: "fit-content", paddingLeft: "20px" }}
                >
                  <Button
                    size="small"
                    href={"#section-" + encodeURIComponent(keyName)}
                    className={classes.glow}
                    // style={{ backgroundColor: "#ffffff" }}
                  >
                    {keyName}
                  </Button>
                </ImageListItem>
              );
            })}
          </ImageList>
        </Grid>
      )}
    </Grid>
  );
}
